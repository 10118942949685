<template>
  <div>
    <v-container
      :class="{
        mobile: $vuetify.breakpoint.smAndDown,
        page: $vuetify.breakpoint.smAndDown
      }"
    >
      <v-card class="msa-list">
        <!-- display:block for ie11 -->
        <section class="d-flex flex-column" style="display:block;">
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-col class="pt-3 pb-0">
              <v-text-field
                id="staff--search-field"
                v-model="searchString"
                aria-label="filter staff list"
                class="data-table--search-field"
                hide-details
                label=""
                outlined
                placeholder="Search staff"
                prepend-inner-icon="mdi-magnify"
                @input="onSearchChangeDebounced"
              >
                <template slot="append">
                  <v-btn
                    v-if="
                      $vuetify.breakpoint.mdAndUp &&
                        fnActionByName(uIActions.LIST_GUEST_STAFF)
                    "
                    id="staff--add-person-button"
                    aria-label="Add Staff"
                    class="primary pt-3 pb-3 pl-5 pr-5 v-btn--add-person"
                    @click="openStaffFilter"
                  >
                    <v-icon class="mr-3">
                      mdi-account-plus-outline
                    </v-icon>
                    Add Staff
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-col class="data-table--header">
              <v-list class="pa-0">
                <v-list-item>
                  <div class="mr-3" style="width:42px" />
                  <v-list-item-content>
                    <v-row class="ml-0">
                      <v-col class="col-sm-5">
                        <v-list-item-title>Name</v-list-item-title>
                      </v-col>
                      <v-col class="col-sm-7">
                        <v-list-item-title>Position</v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                  <v-list-item-action>&nbsp;</v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <!-- Staff list -->
          <div>
            <div
              v-if="
                staff(locationId) &&
                  staff(locationId).length === 0 &&
                  fnActionByName(uIActions.LIST_STAFF)
              "
              class="subheading mt-6 mb-3"
            >
              No results found
            </div>
            <v-list style="background-color: inherit" two-line>
              <div
                v-for="s in staff(locationId)"
                :id="`staff--row-${s.netRegId}`"
                :key="s.detuserid"
              >
                <v-list-item @click="goToStaffAppDetails(s)">
                  <InitialsIcon
                    :given-name="s.firstname"
                    :surname="s.lastname"
                    class="mt-1 mr-3"
                    size="42px"
                  />
                  <v-list-item-content>
                    <v-row
                      :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                      class="ml-0"
                    >
                      <v-col class="col-sm-5">
                        <v-list-item-title>{{
                          s.firstname + " " + s.lastname
                        }}</v-list-item-title>
                      </v-col>
                      <v-col class="col-sm-7">
                        <v-list-item-title>{{
                          s.staffRoleFormatted
                        }}</v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      :aria-label="'select ' + s.firstname + '-' + s.lastname"
                      icon
                      ripple
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </div>
        </section>

        <!-- Other staff list -->
        <section
          v-if="
            extStaff(locationId) &&
              extStaff(locationId).length &&
              fnActionByName(uIActions.LIST_GUEST_STAFF)
          "
        >
          <v-row>
            <v-col class="data-table--header">
              <v-list class="pa-0">
                <v-list-item>
                  <v-list-item-content>
                    <v-row class="ml-14">
                      <v-col class="col-sm-12">
                        <v-list-item-title>Added by you</v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <div>
            <v-list style="background-color: inherit" two-line>
              <div
                v-for="s in extStaff(locationId)"
                :id="`staff-ext--row-${s.netRegId}`"
                :key="s.detuserid"
              >
                <v-list-item @click="goToStaffAppDetails(s)">
                  <InitialsIcon
                    :given-name="s.firstname"
                    :surname="s.lastname"
                    class="mt-1 mr-3"
                    size="42px"
                  />
                  <v-list-item-content>
                    <v-row
                      :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                      class="ml-0"
                    >
                      <v-col class="col-sm-5">
                        <v-list-item-title>{{
                          s.firstname + " " + s.lastname
                        }}</v-list-item-title>
                      </v-col>
                      <v-col class="col-sm-7">
                        <v-list-item-title>{{
                          s.staffRoleFormatted
                        }}</v-list-item-title>
                      </v-col>
                    </v-row>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon ripple>
                      <v-icon class="msa-grey">
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </div>
        </section>
      </v-card>

      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        bottom
        class="primary"
        fab
        fixed
        right
        @click="openStaffFilter"
      >
        <v-icon>mdi-account-plus-outline</v-icon>
      </v-btn>

      <!-- search modal/screen -->
      <v-dialog
        v-if="showStaffFilter"
        v-model="showStaffFilter"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        max-width="675px"
      >
        <StaffFilter
          :location-id="locationId"
          @close="showStaffFilter = false"
          @search="search"
        />
      </v-dialog>
      <v-dialog
        v-if="showStaffSearchResults"
        v-model="showStaffSearchResults"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        content-class="staff--search-results-modal"
        max-width="750px"
        persistent
      >
        <StaffSearchResults
          :filter-params="staffFilterParams"
          @close="showStaffSearchResults = false"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { InitialsIcon } from "@nswdoe/doe-ui-core";
import { mapGetters } from "vuex";
import StaffFilter from "./StaffFilter";
import StaffSearchResults from "../components/StaffSearchResults";
import searchDebounce from "@/mixins/searchDebounce";
import { UI_ACTIONS } from "@/utils/constants";

export default {
  components: {
    InitialsIcon,
    StaffFilter,
    StaffSearchResults
  },
  data() {
    return {
      searchString: this.$store.getters.staffFilter,
      showStaffFilter: false,
      showStaffSearchResults: false,
      staffFilterParams: {},
      isIE: document.documentMode !== null,
      uIActions: UI_ACTIONS
    };
  },
  computed: {
    ...mapGetters({
      staff: "staffByLocation",
      extStaff: "extStaffByLocation",
      locationId: "selectedSchoolId",
      isGov: "isGov",
      isLoading: "isLoading",
      reloadStaffListRequired: "reloadStaffListRequired",
      fnActionByName: "getActionByName"
    }),
    isGovSchool() {
      return this.isGov(this.locationId);
    },
    locationType() {
        const location = this.$store.getters.locations.find(
        l => l.id === this.locationId
        );
        return location ? location.locType : "";
    }
  },
  created: async function() {
    if (this.reloadStaffListRequired) {
      //console.log("Before calling fetchStaffForLocation from Home.vue: locType-", this.locationType);
      await this.$store.dispatch("fetchStaffForLocation", {
        locationId: this.locationId,
        locType: this.locationType
      });
    }
  },
  beforeDestroy() {
    this.$store.commit("setStaffFilter", "");
  },
  mixins: [searchDebounce],
  methods: {
    goToStaffAppDetails(staff) {
      const path = "staff-view/" + staff.detuserid;
      this.$router.push({ path: path });
    },
    openStaffFilter() {
      this.showStaffFilter = true;
    },
    search(params) {
      this.staffFilterParams = params;
      this.showStaffSearchResults = true;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .data-table--header {
  padding: 0 0.75rem;
  font-weight: 600;

  .v-list-item {
    background-color: #cfd8dc;
    padding: 0.75rem 0;
  }

  .v-list-item.theme--dark {
    background-color: #263238;
  }
}

.add-person--button,
.add-person--button:hover {
  position: absolute;
  top: 8px;
  right: 0;
}

.add-person--button-ie-fix,
.add-person--button-ie-fix:hover {
  top: 10px;
}

.v-select,
.v-input__control,
.v-input__slot,
.v-select__slot {
  padding-bottom: 1rem;
  padding-left: 1rem;
}

::v-deep div.v-dialog {
  background-color: #ffffff;
}

::v-deep div[role="listitem"] > a.v-list-item--highlighted {
  background-color: rgba(0, 0, 0, 0.15);
}

::v-deep div[role="listitem"] .v-list-item__mask {
  color: inherit;
  background-color: inherit;
  font-weight: 600;
}

.container {
  position: relative;
}

.btnRight {
  position: absolute;
  right: 20px;
}

.v-menu__content.v-autocomplete__content .v-card {
  -webkit-overflow-scrolling: touch;
}

.v-btn--bottom:not(.v-btn--absolute) {
  bottom: 90px;
}

::v-deep div.v-input__append-inner {
  margin-top: 10px;
  margin-bottom: 10px;
}

#staff--add-person-button {
  height: auto;
}

::v-deep .v-dialog.staff--search-results-modal {
  min-height: 50vh;
}

::v-deep {
  .v-list {
    padding: 0;
  }

  .v-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .theme--dark {
    .v-list-item {
      border-bottom: 1px solid #6e6e6e;
    }
  }
}
</style>
