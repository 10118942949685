import axios from 'axios';
import { getApiUrl } from '@/utils/config';
import { buildHttpHeader, handleServerError } from '@/utils/utils';

const fetchSettings = (locationId) => {

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  return axios.get(`${getApiUrl()}/UAMS/settings?groupId=NOTIFICATIONS&locationId=${locationIdWithoutNG}`,
  {
    headers: buildHttpHeader()
  })
  .then((response) => response.data)
  .catch((err) => {
    handleServerError(err.response);
  });
};

const saveSetting = (key, value, locationId) => {
  const encodedKey = encodeURIComponent(key);
  const encodedValue = encodeURIComponent(value);

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  return axios.post(`${getApiUrl()}/UAMS/settings?groupId=NOTIFICATIONS&key=${encodedKey}&value=${encodedValue}&locationId=${locationIdWithoutNG}`,
      null, {
      headers: buildHttpHeader()
    })
    .catch((err) => {
      handleServerError(err.response);
    });
};

export default { fetchSettings, saveSetting }
