const searchFilter = (sourceStrs, searchStr) => {
  const sourceStrings = sourceStrs.filter(s => s != null);
  const searchStrings = searchStr.split(" ").filter(f => f !== "");
  return searchStrings.every(ss => {
    return (
      sourceStrings.findIndex(source =>
        source
          .toLowerCase()
          .split(" ")
          .some(s => s.startsWith(ss.toLowerCase()))
      ) !== -1
    );
  });
};

const searchFilterContains = (sourceStrs, searchStr) => {
  const sourceStrings = sourceStrs.filter(s => s != null);
  const searchStrings = searchStr.split(" ").filter(f => f !== "");
  return searchStrings.every(ss => {
    return (
      sourceStrings.findIndex(
        source => source.toLowerCase().indexOf(ss.toLowerCase()) !== -1
      ) !== -1
    );
  });
};

export { searchFilter, searchFilterContains };
