import staffApi from 'api/staff';
import {searchFilterContains} from '@/utils/filters';
import capitalise from "@/filters/capitalise";

const addStaffModule = {
  state: {
    addStaff: {},
    staffSearchResult: [],
    staffSearchResultFilter: ""
  },
  getters: {
    addStaff: state => state.addStaff,
    staffSearchResult: state => {
      return state.staffSearchResultFilter ?
        state.staffSearchResult.filter((s) => searchFilterContains([(s.firstname + ' ' + s.lastname), s.staffRoleFormatted, s.schoolName], state.staffSearchResultFilter)) :
        state.staffSearchResult;
    },
  },
  mutations: {
    setAddStaff(state, staff) {
      state.addStaff = staff;
    },
    setStaffSearchResult(state, searchResult) {
      const mappedResult = searchResult.map((staff) => {
        let location;
        if (staff.detPepLocationCode && staff.location && staff.location.length) {
          location = staff.location.find((l) => {return l.locationId === staff.detPepLocationCode});
        }

        if (!location && staff.location && staff.location.length) {
          location = staff.location[0];
        }

        const schoolName = location ? location.locationName : '';

        return Object.assign(staff, {schoolName});
      });
      state.staffSearchResultFilter = "";
      state.staffSearchResult = mappedResult;
    },
    setStaffSearchResultFilter(state, filter) {
      state.staffSearchResultFilter = filter;
    }
  },
  actions: {
    async searchStaff({commit}, {locationId, givenName, lastName, detUserId}) {
      commit('setIsLoading', true);
      return await staffApi.staffSearch(locationId, givenName, lastName, detUserId)
      .then((result) => {
        result?.map(s => s.staffRoleFormatted = capitalise((s.staffRole || "").toLowerCase().split(".").join(" ")));
        commit('setStaffSearchResult', result);
        return result;
      })
      .finally(() => { commit('setIsLoading', false); });
    }
  }
};

export default addStaffModule;
