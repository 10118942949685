import locationsApi from "@/api/locations";

export default {
    methods: {
        logout: function() {
            locationsApi.clearPermissionsCache();
            this.$OAuth.logout();
            return false;
        }
    }
}