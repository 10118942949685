import Vue from 'vue';
import settingsApi from 'api/settings';

const settingsModule = {
  state: {
    settingsByLocation: {}
  },
  getters: {
    settingsByLocation: state => locationId => state.settingsByLocation[locationId]
  },
  mutations: {
    setSettings(state, {settings, locationId}) {
      const newSettings = settings.map(s => {
        if (s.type.toLowerCase() === 'boolean') {
          // convert to boolean to bind to component
          s.value = s.value.toString().toLowerCase() === 'true';
        }
        return s;
      });
      Vue.set(state.settingsByLocation, locationId, newSettings);
    }
  },
  actions: {
    async fetchSettings({commit}, locationId) {
      commit('setIsLoading', true);
      await settingsApi.fetchSettings(locationId)
        .then((settings) => {
          commit('setSettings', {settings, locationId});
        })
        .finally(() => {
          commit('setIsLoading', false);
        });
    },
    setSetting({commit}, {key, value, locationId}) {
      commit('setIsLoading', true);
      settingsApi.saveSetting(key, value, locationId)
        .finally(() => {
          commit('setIsLoading', false);
        });
    }
  }
}

export default settingsModule;