<script>
import { InitialsIcon, NavigationDrawer } from "@nswdoe/doe-ui-core";
import auth from "@/mixins/auth";
import {
  isContractor,
  isCorporate,
  isPrincipalRole,
  isTeacher
} from "@/utils/utils";
import {
  ANNUAL_ATTESTATION_MENU_ITEMS,
  DELEGATION_MENU_ITEMS,
  PENDING_REQUEST_MENU_ITEMS,
  PROVISION_MENU_ITEMS,
  REQUEST_HISTORY_MENU_ITEMS,
  SETTING_MENU_ITEMS,
  UI_ACTIONS,
  USER_ROLES
} from "@/utils/constants";
import { mapGetters } from "vuex";
import store from "../store";

export default {
  name: "MsaNavMenu",
  components: { NavigationDrawer, InitialsIcon },
  computed: {
    ...mapGetters({
      selectedSchoolId: "selectedSchoolId",
      requestsByStatus: "requestsByStatus",
      fnActionByName: "getActionByName",
      pendingRequestsCount: "pendingRequestsCount",
      attestationStatus: "attestationWindowStatus"
    }),
    profile() {
      return this.$OAuth?.data.profile || {};
    },
    jobTitle() {
      const roles = this.profile.detroles;
      if (roles && roles.length) {
        if (isPrincipalRole(roles)) {
          return USER_ROLES.PRINCIPAL;
        }
        if (isTeacher(roles)) {
          return USER_ROLES.TEACHER;
        }
        if (isCorporate(roles)) {
          return USER_ROLES.CORPORATE;
        }
        if (isContractor(roles)) {
          return USER_ROLES.CONTRACTOR;
        }
      }
      return USER_ROLES.STAFF;
    },
    isGovSchool() {
      return store.state.locationsModule.locations.find(
        l => l.id === this.selectedSchoolId
      )?.isGov;
    },
    menuItems() {
      const menuItems = [...PROVISION_MENU_ITEMS];
      const PR = {
        ...PENDING_REQUEST_MENU_ITEMS,
        notificationCount: this.pendingRequestsCount(this.selectedSchoolId)
      };
      this.fnActionByName(UI_ACTIONS.LIST_DELEGATES) &&
        menuItems.push(DELEGATION_MENU_ITEMS);
      this.fnActionByName(UI_ACTIONS.ANNUAL_ATTESTATION) &&
        this.isGovSchool &&
        menuItems.push(ANNUAL_ATTESTATION_MENU_ITEMS);
      this.fnActionByName(UI_ACTIONS.LIST_PREFERENCES) &&
        menuItems.push(SETTING_MENU_ITEMS);
      this.fnActionByName(UI_ACTIONS.LIST_PENDING_REQUESTS) &&
        menuItems.push(PR);

      this.fnActionByName(UI_ACTIONS.LIST_REQUEST_HISTORY) &&
        menuItems.push(REQUEST_HISTORY_MENU_ITEMS);
      return menuItems;
    }
  },
  mixins: [auth]
};
</script>

<template>
  <NavigationDrawer
    :class="'att-status-' + attestationStatus"
    :items="menuItems"
    width="256"
  >
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      slot="preMenu"
      class="ml-0 mb-3 mt-4 pr-1"
      style="border-bottom: 1px solid lightgrey"
    >
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="d-flex align-end pr-2">
            <div id="nav-bar--user-details">
              <InitialsIcon
                :given-name="profile.given_name"
                :surname="profile.sn"
                aria-hidden="true"
              />
              <h2 class="pt-2">
                {{ profile.given_name + " " + profile.sn }}
              </h2>
              <v-list-item-subtitle class="pt-1">
                {{ jobTitle }}
              </v-list-item-subtitle>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <a id="nav-bar--logout" @click="logout">
              <div class="d-flex align-center pl-6 mb-3">
                <v-icon class="pr-3" color="red">mdi-logout-variant</v-icon>
                <span class="red--text">Log out</span>
              </div>
            </a>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <template v-slot:postMenu>
      <div class="post-menu">
        <div class="nav-footer">
          <img
            alt="Doe logo"
            class="logo__image"
            src="../../public/assets/nsw-footer-logo.svg"
          />
        </div>
        <a
          title="MSA user guide"
          target="_blank"
          rel="noopener noreferrer"
          download="MSA_User_Guide.docx"
          href="https://education.nsw.gov.au/content/dam/main-education/inside-the-department/technology/media/documents/Manage_Staff_Access_MSA_user_guide.pdf"
        >User guide</a>
      </div>
    </template>
  </NavigationDrawer>
</template>
