import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// plugins
import vuetify from "./plugins/vuetify";
import { OAuth } from "@nswdoe/doe-ui-core";
import env from "./plugins/env";
import { getConfig } from "@/utils/config";

import "@/directives/adsFocus";
import { filters } from "@/utils/utils";
import { LicenseManager } from "@ag-grid-enterprise/core";
import {
  AG_KEY,
  APP_SELECTOR,
  ENV_URL,
  NODE_ENV,
  VUE_APP_OAUTH_CLIENT_ID,
  VUE_APP_OAUTH_LOGOUT_URL,
  VUE_APP_OAUTH_REDIRECT_URL,
  VUE_APP_OAUTH_URL,
  VUE_APP_TOKEN_URI
} from "./utils/constants";

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

LicenseManager.setLicenseKey(AG_KEY);

Vue.config.productionTip = false;

// Global event bus
Vue.prototype.$eventHub = new Vue();

// fetch env vars from server
Vue.use(env);

if (process.env.NODE_ENV === NODE_ENV) {
  Vue.prototype.$env.init(ENV_URL).then(function() {
    initApp();
  });
} else {
  initApp();
}

function initOAuth() {
  const clientId = getConfig(VUE_APP_OAUTH_CLIENT_ID);
  const scope = `openid ${clientId}/.default`;
  const authOptions = {
    router,
    pkce: true,
    secureApp: true,
    forceProdMode: true,
    authoriseUri: getConfig(VUE_APP_OAUTH_URL),
    tokenUri: getConfig(VUE_APP_TOKEN_URI),
    logoutUri: getConfig(VUE_APP_OAUTH_LOGOUT_URL),
    params: {
      response_type: "code",
      client_id: clientId,
      redirect_uri: encodeURIComponent(getConfig(VUE_APP_OAUTH_REDIRECT_URL)),
      scope: encodeURIComponent(scope)
    }
  };
  Vue.use(OAuth, authOptions);
}

function initApp() {
  initOAuth();

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount(APP_SELECTOR);
}
