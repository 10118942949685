import Vue from "vue";
import delegatesApi from "api/delegates";
import capitalise from "../../filters/capitalise";
import { searchFilterContains } from "@/utils/filters";

const delegatesModule = {
  state: {
    delegatesByLocation: {},
    delegatesFullByLocation: {},
    delegatesFilter: "",
    isDelegatesUpdated: false
  },
  getters: {
    delegatesByLocation: state => locationId => {
      //console.log("delegatesByLocation invoked and current delegatesByLocation are: " + JSON.stringify(state.delegatesByLocation));
      if (locationId == null) {
        return [];
      }
      return state.delegatesFilter && state.delegatesByLocation[locationId]
        ? state.delegatesByLocation[locationId].filter(s =>
            searchFilterContains(
              [s.firstname, s.lastname, s.staffRoleFormatted],
              state.delegatesFilter
            )
          )
        : state.delegatesByLocation[locationId];
    },
    delegatesFullByLocation: state => locationId => {
      if (locationId == null) {
        return [];
      }
      return state.delegatesFilter && state.delegatesFullByLocation[locationId]
        ? state.delegatesFullByLocation[locationId].filter(s =>
            searchFilterContains(
              [s.firstname, s.lastname, s.staffRoleFormatted],
              state.delegatesFilter
            )
          )
        : state.delegatesFullByLocation[locationId];
    },
    delegate: state => (locationId, staffId) => {
      if (
        locationId == null ||
        locationId === "" ||
        staffId == null ||
        staffId === ""
      ) {
        return {};
      }

      const delegates = state.delegatesByLocation[locationId] || [];
      return delegates.find(s => s.detuserid === staffId);
    },
    getUpdatedDelegatesList: state => state.getUpdatedDelegatesList,
    delegatesFilter: state => state.delegatesFilter
  },
  mutations: {
    setDelegatesFilter(state, filter) {
      state.delegatesFilter = filter;
    },
    setDelegates(state, { locationId, delegates }) {
      state.isDelegatesUpdated = false;
      // use Vue.set because we are not replacing the entire object
      //console.log("setDelegates invoked with latest delegates as: " + JSON.stringify(delegates));
      Vue.set(state.delegatesByLocation, locationId, delegates);
    },
    setDelegatesFull(state, { locationId, delegates: delegatesFull }) {
      state.isDelegatesUpdated = false;
      // use Vue.set because we are not replacing the entire object
      Vue.set(state.delegatesFullByLocation, locationId, delegatesFull);
    },
    setUpdatedDelegatesList(state, { getUpdatedDelegatesList }) {
      state.getUpdatedDelegatesList = getUpdatedDelegatesList;
      //console.log("setUpdatedDelegatesList updated the delegates list as: " + JSON.stringify(state.getUpdatedDelegatesList));
    }
  },
  actions: {
    removeDelegateLocal({ commit, getters }, { locationId, detUserId }) {
      //console.log("removeDelegateLocal request received for location:"+locationId+" for user:"+detUserId);
      const delegates = getters.delegatesByLocation(locationId);
      //console.log("Current delegates list from removeDelegateLocal: " + JSON.stringify(delegates));
      commit("setDelegates", {
        locationId: locationId,
        delegates: delegates.filter(d => d.detuserid !== detUserId)
      });
    },
    updateDelegateLocal({ getters }, { locationId, detUserId, fullAccess }) {
      //console.log("updateDelegateLocal request received for location:"+locationId+" for user:"+detUserId);
      const delegates = getters.delegatesByLocation(locationId);
      //console.log("Current delegates list from updateDelegateLocal: " + JSON.stringify(delegates));
      const existing = delegates.find(d => d.detuserid === detUserId);
      if (existing) {
        return Vue.set(existing, "fullAccess", fullAccess ? "Y" : "N");
      }
      // add delegate from staff list
      const staff = getters.staff(locationId, detUserId);
      if (staff) {
        delegates.push(
          Object.assign({}, staff, { fullAccess: fullAccess ? "Y" : "N" })
        );
        const fullName = d => {
          return (d.firstname + " " + d.lastname).toLowerCase();
        };
        delegates.sort((a, b) => {
          return fullName(a) < fullName(b)
            ? -1
            : fullName(a) > fullName(b)
            ? 1
            : 0;
        });
      }
    },
    async fetchDelegatesForLocation({ commit, state }, {
            locationId: locationId,
            locType: locType
            }) {
      if (locationId == null || locationId === "") {
        return;
      }
      if (locType == null || locType === "") {
        return;
      }

      if (
        state.delegatesByLocation[locationId] &&
        state.isDelegatesUpdated === false
      ) {
        commit("setIsLoading", true);

        // data already in store
        return await new Promise(resolve => {
          resolve(state.delegatesByLocation[locationId]);
        }).finally(() => {
          commit("setIsLoading", false);
        });
      }

      //console.log("Before calling getDelegatesForLocation from delegates: locType-", locType);
      commit("setIsLoading", true);
      return await delegatesApi
        .getDelegatesForLocation(locationId, locType)
        .then(response => {
          const delegatesList = response.map(s =>
            Object.assign(s, {
              firstname: capitalise(s.firstname),
              lastname: capitalise(s.lastname),
              staffRoleFormatted: capitalise(
                (s.staffRole || "")
                  .toLowerCase()
                  .split(".")
                  .join(" ")
              )
            })
          );
          commit("setDelegates", {
            locationId: locationId,
            delegates: delegatesList
          });
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    },

    async fetchDelegatesFullForLocation({ commit, state }, locationId) {
      if (locationId == null || locationId === "") {
        return;
      }

      if (
        state.delegatesByLocation[locationId] &&
        state.isDelegatesUpdated === false
      ) {
        commit("setIsLoading", true);

        // data already in store
        return await new Promise(resolve => {
          resolve(state.delegatesFullByLocation[locationId]);
        }).finally(() => {
          commit("setIsLoading", false);
        });
      }

      commit("setIsLoading", true);
      return await delegatesApi
        .getDelegatesFullDataForLocation(locationId)
        .then(response => {
          const delegatesList = response.map(s =>
            Object.assign(s, {
              firstname: capitalise(s.firstname),
              lastname: capitalise(s.lastname),
              staffRoleFormatted: capitalise(
                (s.staffRole || "")
                  .toLowerCase()
                  .split(".")
                  .join(" ")
              )
            })
          );
          commit("setDelegatesFull", {
            locationId: locationId,
            delegates: delegatesList
          });
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    }
  }
};

export default delegatesModule;
