<template>
  <v-dialog
    id="app--dialog"
    v-model="valueInternal"
    :width="width"
    @input="handleReturn"
  >
    <v-card
      id="alert-dialog"
      v-ads-trap-focus
      aria-describedby="alert-dialog--message"
      aria-labelledby="alert-dialog--title"
      role="alertdialog"
    >
      <slot name="content">
        <v-card-title class="title">
          <v-container fluid>
            <div v-if="type" class="d-flex align-center">
              <div class="mr-2">
                <v-icon v-if="type === 'success'" color="#00A908" large>
                  mdi-check-circle
                </v-icon>
                <v-icon v-if="type === 'warning'" color="warning" large>
                  mdi-alert-circle-outline
                </v-icon>
                <v-icon v-if="type === 'error'" color="error" large>
                  mdi-alert-circle
                </v-icon>
              </div>
              <h1 id="alert-dialog--title" class="title">
                {{ title }}
              </h1>
            </div>
            <div v-if="!type">
              <h1 id="alert-dialog--title" class="title">
                {{ title }}
              </h1>
            </div>
          </v-container>
        </v-card-title>

        <slot name="cardText">
          <v-card-text class="pt-0 pb-4">
            <v-container fluid>
              <span id="alert-dialog--message" class="msa-text-grey">{{
                message
              }}</span>
            </v-container>
          </v-card-text>
        </slot>

        <slot name="cardActions">
          <v-card-actions>
            <v-spacer />
            <div v-for="(action, i) in actionsList" :key="i" class="mr-3">
              <v-btn
                ref="action-buts"
                :aria-label="action.name"
                :class="{
                  ['btn-type--' + action.type]: true,
                  primary: action.type === 'primary'
                }"
                :outlined="action.type === 'secondary'"
                :text="action.type === 'tertiary'"
                @click="handleReturn(action.name)"
              >
                {{ action.name }}
              </v-btn>
            </div>
          </v-card-actions>
        </slot>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
import { wait } from "@/utils/utils";

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    callback: {
      type: Function,
      default: null
    },
    actions: {
      type: [String, Array],
      default: null
    },
    type: {
      type: String,
      default: null
    },
    value: {
      type: Boolean
    },
    width: {
      type: String,
      default: "420px"
    }
  },
  data() {
    return {
      actionsList: [],
      autoClose: false,
      valueInternal: this.value
    };
  },
  created() {
    if (typeof this.actions === "string") {
      const actionsList =
        this.actions && this.actions.length ? this.actions.split("|") : ["ok"];
      return (this.actionsList = actionsList.map(aName => {
        return { name: aName, type: "tertiary" };
      }));
    }
    this.actionsList = this.actions;
  },
  mounted() {
    this.focusOnOpen();
  },
  watch: {
    value: function(val) {
      this.valueInternal = val;
      if (val) {
        this.focusOnOpen();
      }
    }
  },
  methods: {
    open() {
      this.valueInternal = true;
      this.autoClose = true;
      this.focusOnOpen();
    },
    getElementsByTagNames(tags, rootId) {
      const scope = rootId ? document.getElementById(rootId) : window;
      var elements = [];
      for (const t of tags) {
        const elementsByTag = [...scope.getElementsByTagName(t)];
        elements = elements.concat(
          elementsByTag.filter(e => e.offsetParent != null)
        );
      }
      return elements.sort((e, e1) =>
        (e.compareDocumentPosition(e1) & 4) === 4 ? 1 : -1
      );
    },
    async focusOnOpen() {
      if (!this.actionsList?.length) return;
      const primaryIndex = this.actionsList.findIndex(
        a => a.type === "primary"
      );
      const index = primaryIndex === -1 ? 0 : primaryIndex;
      await wait(500);
      this.$refs["action-buts"]?.[index].$el?.focus();
    },
    handleReturn(result) {
      if (this.callback) {
        this.callback(result);
      }
      if (this.autoClose) {
        this.valueInternal = false;
      }
    }
  }
};
</script>

<style scoped>
div.v-card {
  padding-top: 5px;
  padding-left: 5px;
}

div.container {
  padding: 0px;
}

i {
  margin-top: 5px;
}

.btn-type--primary {
  background: #041e42;
  color: #ffffff;
}

.btn-type--secondary {
  background: #ffffff;
  color: #041e42;
}

.btn-type--tertiary {
  background: rgba(255, 255, 255, 0);
  color: #041e42;
}
</style>
