import axios from 'axios';
import { getApiUrl } from '@/utils/config';
import { buildHttpHeader } from '@/utils/utils';
import {handleServerError} from "../utils/utils";

const getRequestHistory = (locationId) => {

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  return axios.get(`${getApiUrl()}/UAMS/requests/history/${locationIdWithoutNG}`,
  {
    headers: buildHttpHeader()
  })
  .then((response) => {
    return response.data.map((history) => {
      if (history.application) {
        Object.assign(history.application, { icon: window.essentialIconCodesMap ? window.essentialIconCodesMap[history.application.code] || 'my-generic-blank' : 'my-generic-blank' });
      }
      return history;
    });
  })
    .catch((err) => {
      handleServerError(err.response || err);
    });
};

const getAllPendingRequestsByLocation = (locationId) => {
  let locationIdWithoutNG = String(locationId).replace("NG", "");
  return axios.get(`${getApiUrl()}/UAMS/requests/pending/${locationIdWithoutNG}`,
    {
      headers: buildHttpHeader()
    })
    .then((response) => {
      return response.data.map((history) => {
        if (history.application) {
          Object.assign(history.application, { icon: window.essentialIconCodesMap ? window.essentialIconCodesMap[history.application.code] || 'my-generic-blank' : 'my-generic-blank' });
        }
        return history;
      });
    })
    .catch((err) => {
      handleServerError(err.response || err);
    });
};

const getPendingRequestsCountByLocation = (locationId) => {
  let locationIdWithoutNG = String(locationId).replace("NG", "");
  return axios.get(`${getApiUrl()}/UAMS/requests/pending_count/${locationIdWithoutNG}`,
    {
      headers: buildHttpHeader()
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      handleServerError(err.response || err);
    });
};

const actionRequest = (locationId, requestId, comment, approve) => {

  let locationIdWithoutNG = String(locationId).replace("NG", "");

  const action = approve ? 'approve' : 'reject';
  const payload = {
    approvalID: requestId,
    approverComments: comment,
    locationID: parseInt(locationIdWithoutNG)
  };

  const options = {
    headers: buildHttpHeader()
  }

  return axios.put(`${getApiUrl()}/UAMS/request/${action}`, payload, options)
  .then((response) => response.data)
    .catch((err) => {
      handleServerError(err.response || err);
    });
}

export default { getRequestHistory, actionRequest, getPendingRequestsCountByLocation, getAllPendingRequestsByLocation };
