<template>
  <div
    id="msa-app"
    :class="{'mobile': $vuetify.breakpoint.smAndDown}"
  >
    <v-app>
      <MsaAppBar
        @banner="setBanner($event)"
      />

      <MsaNavMenu />

      <main id="main-content">
        <v-main
          class="primary--text"
          v-if="iniDataLLoaded"
        >
          <Banner
            v-if="$vuetify.breakpoint.mdAndUp && banner"
            :icon="banner.icon"
            :heading="banner.heading"
            :subtitle="banner.subtitle"
          />
          <v-container
            :class="{'pa-0 mobile': $vuetify.breakpoint.smAndDown}"
          >
            <router-view v-if="locationId !== null && locationId !== ''" />
            <h4
              v-else
              class="pt-8 pl-8 subtitle-1"
            >
              Select a school to continue.
            </h4>
          </v-container>
        </v-main>
      </main>

      <BlockUI v-if="isLoading" />
      <Snackbar
        v-model="snackbarProps.show"
        :type="snackbarProps.type"
        :icon="snackbarProps.icon"
        :text="snackbarProps.text"
        :allow-dismiss="snackbarProps.allowDismiss"
        :timeout="snackbarProps.timeout"
        :html-text="snackbarProps.htmlText"
      />
      <Dialog
        v-if="showErrorDialog"
        :title="`${errorTitle} ${errorCode ? '(' + errorCode + ')' : ''}`"
        type="error"
        actions="Ok"
        ref="genericError"
        :value="true"
        :callback="errorCallback"
        :message="errorMessage"
      />
    </v-app>
  </div>
</template>

<script>
import Snackbar from './components/Snackbar';
import Dialog from './components/Dialog';
import {
  BlockUI, Banner
} from '@nswdoe/doe-ui-core';
import {getConfig} from '@/utils/config';

import {
  mapGetters
} from 'vuex';
import MsaAppBar from "@/components/MsaAppBar.vue";
import MsaNavMenu from "@/components/MsaNavMenu.vue";
import auth from "@/mixins/auth";

export default {
  components: {
    MsaNavMenu,
    MsaAppBar,
    BlockUI,
    Snackbar,
    Dialog,
    Banner
  },
  data() {
    return {
      banner: {},
    }
  },
  mixins: [auth],
  created() {
    const googleAnalyticsId = getConfig('VUE_APP_GOOGLE_ANALYTICS_ID');
    if (googleAnalyticsId && googleAnalyticsId !== '') {
      let googleAnalyticsSrc = document.createElement('script');
      googleAnalyticsSrc.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`);
      document.body.appendChild(googleAnalyticsSrc);

      window.dataLayer = window.dataLayer || [];
      const gtag = function () {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', googleAnalyticsId);
    }
    if (window.matchMedia('(prefers-color-scheme: dark)')?.matches
    ) {
      this.$vuetify.theme.dark = true;
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      iniDataLLoaded: 'iniDataLoaded',
      snackbarProps: 'snackbar',
      error: 'error',
      locationId: 'selectedSchoolId',
    }),
    showErrorDialog() {
      return this.error.show;
    },
    errorTitle() {
      return this.error.title;
    },
    errorCode() {
      return this.error.code;
    },
    errorMessage() {
      return this.error.message;
    }
  },
  methods: {
    setBanner: function(banner) {
      this.banner = banner;
    },
    errorCallback: function () {
      if (typeof this.$store.getters.error.callback === 'function') {
        this.$store.getters.error.callback();
      }
      this.$store.commit('showError', {
        show: false
      });
    }
  }
}
</script>

<style lang="scss">
@import './scss/ads.scss';
</style>
