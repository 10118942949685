import { GRID_PAGE_SIZE, PAGE_FORMATS, STAFF_TYPES } from "@/utils/constants";

const msaLocalModule = {
  state: {
    staffType: STAFF_TYPES.internal,
    pageFormat: PAGE_FORMATS.multi,
    pageSize: GRID_PAGE_SIZE.small,
    attCurrentPage: 0
  },
  getters: {
    staffType: state => state.staffType,
    pageFormat: state => state.pageFormat,
    pageSize: state => state.pageSize,
    attCurrentPage: state => state.attCurrentPage
  },
  mutations: {
    setStaffType(state, type) {
      state.staffType = type;
    },
    setPageFormat(state, format) {
      state.pageFormat = format;
    },
    setPageSize(state, size) {
      state.pageSize = size;
    },
    setAttCurrentPage(state, pageNum) {
      state.attCurrentPage = pageNum;
    }
  }
};

export default msaLocalModule;
