<template>
  <div 
    class="d-flex align-center justify-center"
    :style="{
      'background-color': backgroundColor,
      'border-radius': '50%', 
      'width': computedSize,
      'height': computedSize
    }"
  >
    <slot name="icon">
      <v-icon
        :size="computedIconSize"
        :style="{'color': color}"
        :class="{'material-icons-outlined': outlined}"
      >
        {{ icon }}
      </v-icon>
    </slot>
  </div>
</template>

<script>
  export default {
    name: "CircleIcon",
    props: {
      outlined: {
        type: Boolean,
        default: true
      },
      icon: {
        type: String, 
        default: "mdi-alert-outline"
      },
      backgroundColor: {
        type: String,
        default: "#407EC9"
      },
      color: {
        type: String,
        default: "#FFFFFF"
      },
      size: {
        type: String,
        default: "48"
      },
      iconSize: {
        type: String,
        default: null
      }
    },
    computed: {
      computedSize() {
        return this.parseSize(this.size);
      },
      computedIconSize() {
        const size = this.iconSize ? this.iconSize : parseInt(this.size) * 0.65;
        return this.parseSize(size);
      }
    },
    methods: {
      parseSize(size) {
        if (!size) {
          return "";
        }
        return parseInt(size) + "px";
      },
    }
  }
</script>
