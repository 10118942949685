import Vue from "vue";
import staffApi from "api/staff";
import { searchFilterContains } from "@/utils/filters";
import capitalise from "@/filters/capitalise";
import { determineAccessLabel } from "@/utils/apps";

const appsModule = {
  state: {
    appFilter: "",
    apps: {},
    schoolApps: {},
    unSyncedApps: {},
    selectedAppId: "",
    selectedLocType:"",
    taskList: {},
    userProfile: {},
    reloadApplicationsRequired: false
  },
  getters: {
    userProfile: state => state.userProfile,
    selectedAppId: state => state.selectedAppId,
    selectedLocType: state => state.selectedLocType,
    schoolApps: state => state.schoolApps.apps,
    unSyncedApps: state => (locationId, locType, staffId) => {

      if (
        state.unSyncedApps.locationId === locationId &&
        state.unSyncedApps.locationType === locType &&
        state.unSyncedApps.detUserId === staffId
      ) {
        const apps = state.unSyncedApps.unSyncedApps;
        return state.appFilter
          ? apps.filter(a => {
              const accessLevelLabel = determineAccessLabel(
                a.taskAccessVerifications
              );
              return searchFilterContains(
                [a.applicationDesc, accessLevelLabel],
                state.appFilter
              );
            })
          : apps;
      }
      return [];
    },
    accessRepairPayload: state => {
      const c = state.unSyncedApps;
      c.applicationAccessVerifications = c.unSyncedApps;
      return c;
    },
    applications: state => (locationId, staffId) => {
      if (
        state.apps.locationId === locationId &&
        state.apps.staffId === staffId
      ) {
        const apps = state.apps.apps;
        return state.appFilter
          ? apps.filter(a => {
              const accessLevelLabel = determineAccessLabel(a.tasks);
              return searchFilterContains(
                [a.applicationDesc, accessLevelLabel],
                state.appFilter
              );
            })
          : apps;
      }
      return [];
    },
    roleName: state => (locationId, staffId) => {
      if (
        state.apps.locationId === locationId &&
        state.apps.staffId === staffId
      ) {
        const role = state.apps.roleCode;
        if (role && role.toLowerCase() === "sam") {
          return "Admin Manager";
        }
        return capitalise(role);
      }
      return "";
    },
    application: (state, getters) => (locationId, staffId, appCode) => {
      if (locationId === "") {
        return;
      }

      const apps = getters.applications(locationId, staffId);
      return apps.find(a => a.applicationCode === appCode);
    },
    addableApplications: (state, getters) => (locationId, staffId) => {
      const apps = getters.applications(locationId, staffId);
      return apps.filter(
        a =>
          !a.taskList ||
          (a.taskList.every && a.taskList.every(t => !t.provisioned))
      );
    },
    taskList: state => appCode => {
      const app = state.apps?.apps?.find(a => a.applicationCode === appCode);
      return app ? app.tasks : [];
    },
    selectedAppTaskList: state => () => {
      const app = state.schoolApps.apps?.find(
        a => a.applicationId === state.selectedAppId
      );
      return app
        ? app.tasks.map(t => ({ taskName: t.taskName, taskId: t.taskId, taskDesc: t.taskDesc }))
        : [];
    },
    reloadApplicationsRequired: state => state.reloadApplicationsRequired
  },
  mutations: {
    setUserProfile(state, userProfile) {
      state.userProfile = userProfile;
    },
    setSelectedAppId(state, selectedAppId) {
      state.selectedAppId = selectedAppId;
    },
    setAppFilter(state, filter) {
      state.appFilter = filter;
    },
    setApps(state, { locationId, staffId, apps, roleCode }) {
      state.apps = { locationId, staffId, apps, roleCode };
    },
    setUnSyncedApps(state, data) {
      state.unSyncedApps = { ...data };
    },
    setSchoolApps(state, { apps }) {
      state.schoolApps = { apps };
    },
    setTaskMeta(state, { app, taskId, metaId, value }) {
      let task = app?.find(t => t.taskId === taskId);
      task && Vue.set(task, metaId, value);
    },
    setReloadApplicationsRequired(state, required) {
      state.reloadApplicationsRequired = required;
    }
  },
  actions: {
    async fetchAppsForUserAtLocation(
      { commit, dispatch, getters },
      { locationId, locType }
    ) {
      // If locationId is empty, return early
      if (!locationId) {
        return;
      }

      // Set isLoading to true
      commit("setIsLoading", true);

      try {
        // Fetch locations if they are not already in the store
        if (!getters.locations.length) {
          await dispatch("fetchLocations");
        }

        //let locType = getters.locations.find(l => l.id === this.locationId).locType
        //console.log("Before calling getAppsAtLocation: locType-", locType);

        // Fetch apps for the location using the staffApi
        const response = await staffApi.getAppsAtLocation(locationId, locType);

        commit("setSchoolApps", { apps: response.applications });

        return response;
      } catch (error) {
        throw new Error("An error occurred: " + error.message);
      } finally {
        commit("setIsLoading", false);
      }
    },

    async fetchAppsForStaffAtLocation(
      { commit, dispatch, getters },
      { locationId, locType, staffId }
    ) {
      //console.log("Inside fetchAppsForStaffAtLocation");

      if (locationId === "") {
        return;
      }
      if (locType === "") {
        return;
      }

      commit("setIsLoading", true);

      // fetch staff, location, and apps if not already in store
      if (!getters.locations.length) {
        await dispatch("fetchLocations");
      }

      if (!getters.staffByLocation || !getters.staffByLocation[locationId]) {
        //console.log("Before calling fetchStaffForLocation: locType-" + locType);
        await dispatch("fetchStaffForLocation", {
            locType: locType,
            locationId: locationId
            });
      }

      let staff = getters.staff(locationId, staffId);
      if (!staff && getters.addStaff.detuserid === staffId) {
        staff = getters.addStaff;
      }

      if (!staff) {
        window.location.href = "/404";
      }

      return await staffApi
        .getAppsForStaffAtLocation(locationId, locType, staffId, staff.netRegId)
        .then(response => {
          return commit("setApps", {
            locationId,
            staffId,
            apps: response.applications,
            roleCode: response.roleCode
          });
        })
        .finally(() => commit("setIsLoading", false));
    },

    async fetchUnSyncedAppsForStaffAtLocation(
      { commit, dispatch, getters },
      { locationId, locType, staffId }
    ) {
      if (locationId === "") {
        return;
      }
      if (locType === "") {
              return;
      }

      commit("setIsLoading", true);

      // fetch staff, location, and apps if not already in store
      if (!getters.locations.length) {
        await dispatch("fetchLocations");
      }

      if (!getters.staffByLocation || !getters.staffByLocation[locationId]) {
        await dispatch("fetchStaffForLocation", {
            locType: locType,
            locationId: locationId
            });
      }

      let staff = getters.staff(locationId, staffId);
      if (!staff && getters.addStaff.detuserid === staffId) {
        staff = getters.addStaff;
      }

      if (!staff) {
        window.location.href = "/404";
      }

      return await staffApi
        .getUnSyncedAppsForStaffAtLocation(locationId, locType, staffId, staff.netRegId)
        .then(response => {
          return commit("setUnSyncedApps", {
            ...response
          });
        })
        .finally(() => commit("setIsLoading", false));
    },

    async repairAccess({ commit, getters, dispatch }) {
      const payload = getters.accessRepairPayload;
      commit("setIsLoading", true);
      commit("showSnackbar", { type: "success", show: false });
      return staffApi
        .repairAccess(payload)
        .then(response => {
          commit("setIsLoading", false);
          if (response.status === 200) {
            commit("showSnackbar", {
              text: response.data,
              type: "success",
              show: true
            });
            commit("setUnSyncedApps", {});
          }
          if (response.status === 206) {
            commit("showSnackbar", {
              text: `${response.data}, updating applications list.`,
              type: "success",
              show: true
            });
            const appsData = JSON.parse(response.config.data);
            dispatch("fetchUnSyncedAppsForStaffAtLocation", {
              locationId: appsData.locationId,
              staffId: appsData.detUserId
            });
          }
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    },

    provisionTask(
      { commit, getters },
      {
        locationId,
        locType,
        locationName,
        detUserId,
        staffId,
        applicationId,
        applicationCode,
        taskId,
        provisioned,
        revokeAll
      }
    ) {
      const application = getters.taskList(applicationCode);
      commit("setTaskMeta", {
        app: application,
        taskId,
        metaId: "isPending",
        value: true
      });
      commit("showSnackbar", { type: "success", show: false });
      const enabledTasks = application
        .filter(t => t.hasAccess)
        .map(t => t.taskId)
        .filter(t => t !== taskId);

      staffApi
        .provisionAppAccess(
          locationId,
          locType,
          locationName,
          detUserId,
          staffId,
          applicationId,
          taskId,
          provisioned,
          enabledTasks,
          revokeAll
        )
        .then(
          () => {
            const toggledTask = application.find(t => t.taskId === taskId);
            toggledTask.hasAccess = provisioned;

            commit("setTaskMeta", {
              app: application,
              taskId,
              metaId: "hasError",
              value: false
            });

            const provisionType = provisioned ? "granted" : "removed";
            commit("showSnackbar", {
              text: "Access has been " + provisionType + ".",
              type: "success",
              show: true
            });
          },
          e => {
            commit("setTaskMeta", {
              app: application,
              taskId,
              metaId: "hasError",
              value: true
            });
            commit("showSnackbar", {
              text: `This attempt was unsuccessful. ${e.message}`,
              type: "error",
              show: true
            });
          }
        )
        .finally(() =>
          commit("setTaskMeta", {
            app: application,
            taskId,
            metaId: "isPending",
            value: false
          })
        );
    },

    deprovisionApp(
      { commit, getters },
      {
        locationId,
        locType,
        locationName,
        detUserId,
        staffId,
        applicationId,
        applicationCode,
        revokeAll
      }
    ) {
      commit("setIsLoading", true);

      // (AP-270) we now allow birthright tasks to be deprovisioned
      const appTasks = getters
        .taskList(applicationCode)
        .filter(t => t.hasAccess)
        .map(t => t.taskId);

      staffApi
        .provisionAppAccess(
          locationId,
          locType,
          locationName,
          detUserId,
          staffId,
          applicationId,
          "",
          false,
          appTasks,
          revokeAll
        )
        .then(() => {
          const tasks = getters.taskList(applicationCode);
          for (const t of tasks) {
            t.hasAccess = false;
          }

          commit("showSnackbar", {
            text: "Access for application has been removed",
            type: "success",
            show: true
          });
        })
        .finally(() => commit("setIsLoading", false));
    }
  }
};

export default appsModule;
