import axios from "axios";
import { getApiUrl } from "@/utils/config";
import { buildHttpHeader, handleServerError } from "@/utils/utils";

const getDelegatesForLocation = (locationId, locType) => {
  if (!locationId) {
    return new Promise(function(resolve) {
      resolve([]);
    });
  }

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  return axios
    .get(`${getApiUrl()}/UAMS/ProfileTemplate/${locType}/${locationIdWithoutNG}/DELEGATE_ACCESS`, {
      headers: buildHttpHeader()
    })
    .then(response => response.data.delegates)
    .catch(err => {
      handleServerError(err.response);
    });
};

const getDelegatesFullDataForLocation = locationId => {
  if (!locationId) {
    return new Promise(function(resolve) {
      resolve([]);
    });
  }

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  return axios
    .get(
      `${getApiUrl()}/UAMS/ProfileTemplate/${locationIdWithoutNG}/DELEGATE_ACCESS_FULL`,
      {
        headers: buildHttpHeader()
      }
    )
    .then(response => response.data.delegates)
    .catch(err => {
      handleServerError(err.response);
    });
};

const getAppsForDelegateAtLocation = (locationId, locType, staffId, netregid) => {

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  let url = `${getApiUrl()}/UAMS/ProfileTemplate/${locType}/${locationIdWithoutNG}/delegates/${staffId}/applications`;

  return axios
    .get(url, {
      headers: buildHttpHeader({ staffNetRegId: netregid })
    })
    .then(response => {
      return response.data.delegatableApplications
        ? response.data.delegatableApplications.map(app =>
            Object.assign(app, {
              icon: window.essentialIconCodesMap
                ? window.essentialIconCodesMap[app.code] || "my-generic-blank"
                : "my-generic-blank"
            })
          )
        : [];
    })
    .catch(err => {
      handleServerError(err.response || err);
    });
};

const provisionAppDelegation = (
  locationId,
  locType,
  locationName,
  detUserId,
  staffId,
  staffRole,
  applicationId,
  applicationTaskId,
  enabledTasks,
  provision,
  provisionAll,
  revokeAll
) => {

  let locationIdWithoutNG = String(locationId).replace("NG", "");

  const data = {
    locationID: locationIdWithoutNG,
    locationType: locType,
    applicationID: applicationId,
    taskID: applicationTaskId,
    staffDetuserID: detUserId,
    staffNetRegId: staffId,
    locationName: locationName,
    enabledTasks: enabledTasks.toString(),
    revokeAll: revokeAll,
    provisionAll: provisionAll,
    staffPosition: staffRole
  };

  const config = {
    headers: buildHttpHeader()
  };

  if (provision) {
    return axios
      .post(`${getApiUrl()}/UAMS/ProfileTemplate/Provision`, data, config)
      .then(response => response)
      .catch(err => {
        handleServerError(err.response);
      });
  }
  return axios
    .delete(
      `${getApiUrl()}/UAMS/ProfileTemplate/Deprovision`,
      Object.assign(config, { data: data })
    )
    .then(response => response)
    .catch(err => {
      handleServerError(err.response);
    });
};

export default {
  getDelegatesForLocation,
  getAppsForDelegateAtLocation,
  provisionAppDelegation,
  getDelegatesFullDataForLocation
};
