<script>
import { mapGetters } from "vuex";
import searchDebounce from "@/mixins/searchDebounce";
import AppBar from "@nswdoe/doe-ui-core/src/AppBar/AppBar.vue";
import AppIcon from "@nswdoe/doe-ui-core/src/AppIcon/AppIcon.vue";
import Profile from "@nswdoe/doe-ui-core/src/Profile/Profile.vue";
import {
  isContractor,
  isCorporate,
  isPrincipalRole,
  isTeacher
} from "@/utils/utils";
import auth from "@/mixins/auth";
import MsaSchoolPicker from "@/components/MsaSchoolPicker.vue";
import { USER_ROLES } from "@/utils/constants";

export default {
  name: "MsaAppBar",
  components: { MsaSchoolPicker, Profile, AppIcon, AppBar },
  data() {
    return {
      routePath: "",
      routeCanGoBack: false,
      routeCanSearch: false,
      mobileAppTitle: "MSA",
      showSearch: false,
      searchString: ""
    };
  },
  mixins: [searchDebounce, auth],
  computed: {
    ...mapGetters({
      locations: "locations",
      selectedSchoolId: "selectedSchoolId"
    }),
    profile() {
      return this.$OAuth?.data.profile || {};
    },
    locationSlot() {
      return this.$vuetify.breakpoint.mdAndUp ? "dataSwitcher" : "extension";
    },
    jobTitle() {
      const roles = this.profile.detroles;
      if (roles && roles.length) {
        if (isPrincipalRole(roles)) {
          return USER_ROLES.PRINCIPAL;
        }
        if (isTeacher(roles)) {
          return USER_ROLES.TEACHER;
        }
        if (isCorporate(roles)) {
          return USER_ROLES.CORPORATE;
        }
        if (isContractor(roles)) {
          return USER_ROLES.CONTRACTOR;
        }
      }
      return USER_ROLES.STAFF;
    },
    showBackOnMobile() {
      return this.$vuetify.breakpoint.smAndDown && this.routeCanGoBack;
    },
    showSchoolPicker() {
      return !this.showBackOnMobile;
    },
    appTitle() {
      const route = this.$router.currentRoute;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.mobileAppTitle != null) {
          const staffId = route.params.staffId;

          const staff =
            (route.path.toLowerCase().endsWith("addapplication")
              ? this.$store.getters.addStaff
              : this.$store.getters.staff(this.selectedSchoolId, staffId)) ||
            {};

          const staffName = staff.firstname || "Staff";
          const staffNamePossessive = this.$options.filters.possessive(
            staffName
          );
          return this.mobileAppTitle
            .replace("$staffPossessive", staffNamePossessive)
            .replace("$staff", staffName);
        }
      }

      return this.routePath.toLowerCase().startsWith("/delegates")
        ? "Manage Delegates"
        : "Manage Staff Access";
    },
    authLoaded() {
      return this.$OAuth?.loaded;
    }
  },
  watch: {
    authLoaded: {
      immediate: true,
      handler(loaded) {
        if (loaded) {
          this.updateRouteMetaSettings();
        }
      }
    },
    $route() {
      this.updateRouteMetaSettings();
    }
  },
  methods: {
    showSearchField() {
      this.showSearch = true;
      setTimeout(() => {
        this.$refs.appHeaderSearchField.focus();
      }, 200);
    },
    updateRouteMetaSettings() {
      this.routePath = this.$router.currentRoute.path;
      const routeMeta = this.$router.currentRoute.meta || {};
      this.routeCanGoBack = routeMeta.canGoBack;
      this.routeCanSearch = routeMeta.searchMutator != null;
      this.mobileAppTitle = routeMeta.mobileAppTitle;
      this.$emit("banner", routeMeta.banner);
      this.showSearch = false;
      this.searchString = "";
    }
  }
};
</script>

<template>
  <AppBar
    :class="{ 'spacer-hidden': showSearch }"
    :color="$vuetify.theme.dark ? 'primary' : 'accent'"
    :extended="$vuetify.breakpoint.smAndDown && showSchoolPicker"
    :show-back-button="showBackOnMobile"
    :show-full-screen="false"
    :title="appTitle"
  >
    <template #appIcon>
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="d-flex flex-row align-center"
      >
        <AppIcon
          :transparent="true"
          alt="MSA logo"
          app-name="my-msa"
          size="3rem"
        />
      </div>
    </template>

    <div
      v-if="$vuetify.breakpoint.smAndDown && showSearch"
      slot="title"
      class="d-flex align-center flex-grow-1"
      hide-details="true"
    >
      <v-input>
        <input
          id="app-bar--search-field"
          ref="appHeaderSearchField"
          v-model="searchString"
          aria-label="Search list"
          class="flex-grow-1"
          placeholder="Search here..."
          type="text"
          @blur="showSearch = searchString !== ''"
          @input="onSearchChangeDebounced(searchString)"
        />
      </v-input>
    </div>

    <template v-slot:[locationSlot]>
      <MsaSchoolPicker :locations="locations" />
    </template>

    <template #profile>
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown && routeCanSearch"
        id="app-bar--search-icon-button"
        :aria-expanded="showSearch"
        aria-controls="app-bar--search-field"
        icon
        @click="showSearchField"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <Profile
        v-if="$vuetify.breakpoint.mdAndUp"
        id="app-bar--profile-icon-button"
        :change-password="false"
        :given-name="profile.given_name"
        :job-title="jobTitle"
        :logout="true"
        :logout-handler="logout"
        :portal-settings="false"
        :surname="profile.sn"
        :update-profile="false"
        :update-secret-question="false"
      />
    </template>
  </AppBar>
</template>
