<template>
  <div
    class="container"
    id="staff-filter--container"
    role="dialog"
    aria-labelledby="staff-filter--header"
    aria-describedby="staff-filter--subheader"
    v-ads-trap-focus
    v-ads-set-focus:add-staff-modal--first-name
  >
    <v-form
      ref="form"
      @submit="doSearch"
      @submit.prevent="validateForm"
    >
      <div class="pa-3">
        <div class="d-flex align-start">
          <div class="mr-4">
            <CircleIcon
              icon="mdi-account-plus-outline"
              size="48"
            />
          </div>
          <div class="mr-12">
            <h3
              id="staff-filter--header"
            >
              Add Staff
            </h3>
            <p 
              class="mb-0"
              id="staff-filter--subheader"
            >
              Enter the details of the DOE staff you are searching for below.
            </p>
            <p>Hit Search to begin.</p>
          </div>
          <v-spacer />
          <v-btn
            icon
            left
            @click="$emit('close')"
            aria-label="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          :class="{'ml-4': $vuetify.breakpoint.smAndUp,
                   'pl-12': $vuetify.breakpoint.smAndUp,
                   'mr-12': $vuetify.breakpoint.smAndUp}"
        >
          <div class="mt-3">
            <v-text-field
              autofocus
              ref="firstName"
              label="First name"
              placeholder=" "
              hint="e.g. Bob"
              persistent-hint
              outlined
              v-model="givenName"
              :counter="50"
              :error-messages="errors"
              :rules="[alphanumeric, val => maxLength(50, val)]"
              @blur="clearErrors"
              id="add-staff-modal--first-name"
            />
          </div>
          <div class="mt-3">
            <v-text-field
              label="Last name"
              placeholder=" "
              hint="e.g. Smith"
              persistent-hint
              outlined
              v-model="lastName"
              :counter="50"
              :error-messages="errors"
              :rules="[alphanumeric, val => maxLength(50, val)]"
              @blur="clearErrors"
              id="add-staff-modal--last-name"
            />
          </div>
          <div class="mt-3">
            <v-text-field
              label="DET UserID"
              placeholder=" "
              hint="e.g. Bob.Smith1"
              persistent-hint
              outlined
              v-model="detUserId"
              :counter="100"
              :rules="[noAts, email, val => maxLength(100, val)]"
              :error-messages="errors"
              @blur="clearErrors"
              id="add-staff-modal--email"
            />
          </div>
          <div class="mt-3">
            <v-btn
              type="submit"
              class="primary pl-4 pr-6"
              height="2.8rem"
            >
              <v-icon class="mr-2">
                mdi-magnify
              </v-icon>
              Search
            </v-btn>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import CircleIcon from '../components/CircleIcon';
import {mapGetters} from "vuex";

export default {
  components: {
    CircleIcon
  },
  data() {
    return {
      loaded: false,
      givenName: "",
      lastName: "",
      detUserId: "",
      errors: []
    };
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
    }),
  },
  created() {
    this.$store.commit("setStaffSearchResult", []);
  },
  methods: {
    clearErrors() {
      this.errors = [];
    },
    validateForm() {
      let isValid = true;
      if (!this.detUserId && !this.givenName && !this.lastName) {
        this.errors = ["At least one search criteria is required"];
        isValid = false;
      }
      if (!this.$refs.form.validate()) {
        isValid = false;
      }
      return isValid;
    },
    doSearch() {
      if (!this.validateForm()) {
        return false;
      }

      const queryParams = Object.assign(
        {},
        this.givenName ? { givenName: this.givenName } : null,
        this.lastName ? { lastName: this.lastName } : null,
        this.detUserId ? { detUserId: this.detUserId } : null
      );

      this.$emit('search', queryParams);

    },
    alphanumeric(val) {
      if (val && val.match(/[^a-zA-Z0-9. -]/)) {
        return "Invalid character(s)";
      }
      return true;
    },
    email(val) {
      if (val && val.match(/[^a-zA-Z0-9._-]/)) {
        return "Invalid character(s)";
      }
      return true;
    },
    noAts(val) {
      if (val && val.indexOf("@") !== -1) {
        this.detUserId = val.substring(0, val.indexOf("@"));
      }
      return true;
    },
    maxLength(maxLength, val) {
      if (val) {
        if (val.length > maxLength) {
          return "Maximum of " + maxLength + " characters";
        }
      }
      return true;
    }
  }
};
</script>

<style scoped>
  div.layout {
      padding-top: 15px;
  }
</style>
