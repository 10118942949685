const primary = Object.freeze({
  navy: '#041E42',
  red: '#CE0037',
  lightblue: '#C7DCF0',
  white: '#FFFFFF'
});

const secondary = Object.freeze({
  blue1: '#1D428A',
  blue2: '#407EC9',
  blue3: '#6CACE4',
  red2: '#EE3C48',
  red3: '#F3B8B5',
});

const userInterface = Object.freeze({
  black: '#151515',
  darkgray: '#2E2F30',
  coolgrey: '#A9B1B4',
  grey60: '#666666',
  grey30: '#B3B3B3',
  grey15: '#D9D9D9',
  grey10: '#E5E5E5',
  grey5: '#F5F5F5',
  grey2: '#FAFAFA'
});

const complementary = Object.freeze({
  purple: '#752F8A',
  orange: '#FF7F2F',
  yellow: '#F9BE00',
  green: '#69B32D'
});

const notification = Object.freeze({
  errorred: '#B81237',
  warningorange: '#DC5800',
  infoblue: '#2E5299',
  successgreen: '#00A908'
});

const allColors = Object.freeze({
  // Primary
  "navy": "#041E42",
  "red": "#CE0037",
  "lightBlue": "#C8DCF0",

  "navyDark": "#6BACE4",
  "redDark": "#EE3C48",
  "lightBlueDark": "#DEECFA",

  // Secondary
  "blue1": "#1D428A",
  "blue2": "#407EC9",
  "blue3": "#6CACE4",
  "red2": "#EE3C48",
  "red3": "#F3B8B5",

  "blue1Dark": "#2B519C",
  "blue2Dark": "#508AD0",
  "blue3Dark": "#93CAFA",
  "red2Dark": "#ED5660",
  "red3Dark": "#FBCECD",

  // Notification
  "infoBlue": "#2E5299",
  "successGreen": "#00A908",
  "warningOrange": "#DC5800",
  "errorRed": "#B81237",

  "infoBlueDark": "#6BACE4",
  "successGreenDark": "#69B32D",
  "warningOrangeDark": "#FF7F2F",
  "errorRedDark": "#EE3C48",

  // Greys
  "dark": "#121212",
  "dark80": "#333333",
  "dark70": "#4C4F55",
  "dark60": "#6D7079",
  "light40": "#A0A5AE",
  "light20": "#E4E4E6",
  "light10": "#F4F4F7",
  "white": "#FFFFFF",

  // Tints for dark theme
  "01dp": "#1E1E1E",
  "02dp": "#232323",
  "03dp": "#252525",
  "04dp": "#272727",
  "06dp": "#2C2C2C",
  "08dp": "#2E2E2E",
  "12dp": "#333333",
  "16dp": "#363636",
  "24dp": "#383838",

  // Tints for navy background
  "navyLight1": "#1A3B69",
  "navyLight2": "#264776",
  "navyLight3": "#2E5285",
  "navyDark1": "#031631"
})

export default Object.freeze({
  ...allColors,
  primary,
  secondary,
  userInterface,
  complementary,
  notification
});