import axios from "axios";
import { getApiUrl } from "@/utils/config";
import { buildHttpHeader, handleServerError } from "@/utils/utils";

const getStaffForLocation = (locationId, locType) => {
  if (!locationId || !locType) {
    return new Promise(function(resolve) {
      resolve([]);
    });
  }
  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/locations/${locType}/${locationIdWithoutNG}/users`;
  return axios
    .get(url, {
      headers: buildHttpHeader()
    })
    .then(response => response.data.staffList)
    .catch(err => {
      handleServerError(err.response);
    });
};

const getExternalStaffForLocation = (locationId, locType) => {
  if (!locationId || !locType) {
    return new Promise(function(resolve) {
      resolve([]);
    });
  }
  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/locations/${locType}/${locationIdWithoutNG}/moreStaff`;
  return axios
    .get(url, {
      headers: buildHttpHeader()
    })
    .then(response => response.data.staffList)
    .catch(err => {
      handleServerError(err.response);
    });
};

const getAppsForStaffAtLocation = (locationId, locType, staffId, netRegId) => {

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/locations/${locType}/${locationIdWithoutNG}/users/${netRegId}/applications?staffDetUserId=${staffId}`;

  return axios
    .get(url, {
      headers: buildHttpHeader()
    })
    .then(response => {
      const apps = response.data.applications
        ? response.data.applications.map(app =>
            Object.assign(app, {
              icon: window.essentialIconCodesMap
                ? window.essentialIconCodesMap[app.applicationCode] ||
                  "my-generic-blank"
                : "my-generic-blank"
            })
          )
        : [];

      return { ...response.data, applications: apps };
    })
    .catch(err => {
      handleServerError(err.response || err);
    });
};

const getUnSyncedAppsForStaffAtLocation = (locationId, locType, staffId, netRegId) => {
  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/verification/location/${locType}/${locationIdWithoutNG}/user/${netRegId}?staffDetUserId=${staffId}`;

  return axios
    .get(url, {
      headers: buildHttpHeader()
    })
    .then(response => {
      const apps = response.data.applicationAccessVerifications
        ? response.data.applicationAccessVerifications.map(app =>
            Object.assign(app, {
              icon: window.essentialIconCodesMap
                ? window.essentialIconCodesMap[app.applicationCode] ||
                  "my-generic-blank"
                : "my-generic-blank"
            })
          )
        : [];
      apps.forEach(app => {
        app.taskAccessVerifications = app.taskAccessVerifications.filter(
          task => task.msaAccessState !== task.controlAccessState
        );
      });
      return {
        ...response.data,
        unSyncedApps: apps.filter(
          app => app?.taskAccessVerifications?.length > 0
        )
      };
    })
    .catch(err => {
      handleServerError(err.response || err);
    });
};
const getAppsAtLocation = async (locationId, locType) => {
  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/ProfileTemplate/applications/${locType}/${locationIdWithoutNG}`;

  try {
    const response = await axios.get(url, { headers: buildHttpHeader() });

    const apps = (response.data.applications || [])
      .map(app => {
        const icon =
          window.essentialIconCodesMap?.[app.applicationCode] ||
          "my-generic-blank";
        return { ...app, icon };
      })
      .sort((a, b) => a?.applicationDesc.localeCompare(b?.applicationDesc));

    return { ...response.data, applications: apps };
  } catch (error) {
    handleServerError(error.response || error);
    throw error;
  }
};

const getStaffAccessOfAppAtLocation = async (locationId, locType, applicationId) => {
  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/provision/query/${locType}/${locationIdWithoutNG}/${applicationId}`;

  try {
    const response = await axios.get(url, { headers: buildHttpHeader() });
    return { ...response.data };
  } catch (error) {
    handleServerError(error.response || error);
    throw error;
  }
};

const getStaffAnnualAttestationForLocation = locationId => {
  if (!locationId) {
    return new Promise(function(resolve) {
      resolve([]);
    });
  }
  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/attestation/query/${locationIdWithoutNG}`;
  return axios
    .get(url, {
      headers: buildHttpHeader()
    })
    .then(response => response.data)
    .catch(err => {
      handleServerError(err.response);
    });
};

const confirmStaffAnnualAttestationForLocation = (
  locationId,
  staffNetRegId
) => {
  if (!locationId) {
    return new Promise(function(resolve) {
      resolve([]);
    });
  }

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/attestation/confirm/${locationIdWithoutNG}/staff/${staffNetRegId}`;
  return axios
    .get(url, {
      headers: buildHttpHeader()
    })
    .then(response => response.data)
    .catch(err => {
      handleServerError(err.response);
    });
};

const removeStaffAnnualAttestationForLocation = (locationId, staffNetRegId) => {
  if (!locationId) {
    return new Promise(function(resolve) {
      resolve([]);
    });
  }

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/attestation/remove/${locationIdWithoutNG}/staff/${staffNetRegId}`;
  return axios
    .get(url, {
      headers: buildHttpHeader()
    })
    .then(response => response.data)
    .catch(err => {
      handleServerError(err.response);
    });
};

const modifyStaffAnnualAttestationForLocation = (locationId, staffNetRegId) => {
  if (!locationId) {
    return new Promise(function(resolve) {
      resolve([]);
    });
  }

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/attestation/modify/${locationIdWithoutNG}/staff/${staffNetRegId}`;
  return axios
    .get(url, {
      headers: buildHttpHeader()
    })
    .then(response => response.data)
    .catch(err => {
      handleServerError(err.response);
    });
};

const provisionAppAccess = (
  locationId,
  locType,
  locationName,
  detUserId,
  staffId,
  appId,
  taskId,
  isGrantPermission,
  enabledTasks,
  revokeAll
) => {
  const config = {
    headers: buildHttpHeader({
      "Content-Type": "application/json",
      staffNetRegId: staffId
    })
  };

  let locationIdWithoutNG = String(locationId).replace("NG", "");

  const data = {
    locationID: locationIdWithoutNG.toString(),
    locationType: locType,
    locationName: locationName,
    applicationID: appId.toString(),
    taskID: taskId.toString(),
    staffDetuserID: detUserId.toString(),
    staffNetRegId: staffId.toString(),
    enabledTasks: enabledTasks.toString(),
    revokeAll: revokeAll
  };
  //console.log("Before calling the /provision or /deprovision API: Payload: "+ JSON.stringify(data));

  const baseUrl = `${getApiUrl()}/UAMS`;
  if (isGrantPermission) {
    return axios.post(`${baseUrl}/provision`, data, config).catch(err => {
      handleServerError(err.response);
    });
  }
  return axios
    .delete(`${baseUrl}/deprovision`, Object.assign(config, { data: data }))
    .catch(err => {
      handleServerError(err.response);
    });
};

const repairAccess = repairPayload => {
  const config = {
    headers: buildHttpHeader({
      "Content-Type": "application/json",
      staffNetRegId: repairPayload
    })
  };

  const baseUrl = `${getApiUrl()}/UAMS`;
  return axios
    .post(`${baseUrl}/verification/synchronise`, repairPayload, config)
    .catch(err => {
      handleServerError(err.response);
    });
};

const staffSearch = (locationId, givenName, lastName, detUserId) => {
  const params = Object.assign(
    {},
    givenName ? { givenName: encodeURI(givenName) } : null,
    lastName ? { lastName: encodeURI(lastName) } : null,
    detUserId ? { detUserId: encodeURI(detUserId) } : null
  );

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/locations/${locationIdWithoutNG}/searchStaff`;

  const httpClient = axios.create();
  httpClient.defaults.timeout = 120000;

  return httpClient
    .get(url, {
      params: params,
      headers: buildHttpHeader()
    })
    .then(response => {
      return response.data.staffList;
    })
    .catch(err => {
      handleServerError(err.response);
      return [];
    });
};

export default {
  getStaffForLocation,
  getExternalStaffForLocation,
  getAppsForStaffAtLocation,
  provisionAppAccess,
  repairAccess,
  staffSearch,
  getAppsAtLocation,
  getStaffAccessOfAppAtLocation,
  getUnSyncedAppsForStaffAtLocation,
  getStaffAnnualAttestationForLocation,
  confirmStaffAnnualAttestationForLocation,
  removeStaffAnnualAttestationForLocation,
  modifyStaffAnnualAttestationForLocation
};
