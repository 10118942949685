import Vue from "vue";
import Vuex from "vuex";

import locationsModule from "./modules/locations";
import staffModule from "./modules/staff";
import appsModule from "./modules/apps";
import delegateAppsModule from "./modules/delegateApps";
import addStaffModule from "./modules/addStaff";
import delegatesModule from "./modules/delegates";
import requestHistoryModule from "./modules/requestHistory";
import settingsModule from "./modules/settings";
import { DET_ROLES } from "@/utils/constants";
import VuexPersistence from "vuex-persist";
import msaLocalModule from "@/store/modules/msaLocal";

Vue.use(Vuex);

const msaLocal = new VuexPersistence({
  key: "msa_state",
  storage: window.localStorage,
  modules: ["msaLocalModule"]
});

export default new Vuex.Store({
  modules: {
    locationsModule,
    staffModule,
    appsModule,
    addStaffModule,
    delegatesModule,
    delegateAppsModule,
    requestHistoryModule,
    settingsModule,
    msaLocalModule
  },
  state: {
    isLoading: false,
    loadingCount: 0,
    error: {},
    snackbar: { show: false },
    iniDataLoaded: false
  },
  getters: {
    isLoading: state => state.isLoading,
    iniDataLoaded: state => state.iniDataLoaded,
    error: state => state.error,
    snackbar: state => state.snackbar,
    isPrincipal: () => {
      const roles = Vue.prototype.$OAuth.data?.profile?.detroles;
      return (
        roles.indexOf(DET_ROLES.PRINCIPAL) !== -1 ||
        roles === DET_ROLES.PRINCIPAL
      );
    }
  },
  mutations: {
    setIsLoading(state, isLoading) {
      isLoading ? state.loadingCount++ : state.loadingCount--;
      state.isLoading = state.loadingCount > 0;
    },
    setIniDataLoaded(state, iniDataLoaded) {
      state.iniDataLoaded = iniDataLoaded;
    },
    showSnackbar(
      state,
      { text, type, show, timeout, allowDismiss, icon, htmlText }
    ) {
      state.snackbar = {
        timeout,
        text,
        type,
        show,
        allowDismiss,
        icon,
        htmlText
      };
    },
    showError(state, { show, title, code, message, callback }) {
      if (show) {
        state.error = {
          show,
          title: title || "Error",
          code,
          message,
          callback
        };
        return;
      }

      Vue.set(state.error, "show", false);
      setTimeout(() => {
        state.error.title = "";
        state.error.code = "";
        state.error.message = "";
      }, 300);
    }
  },
  plugins: [msaLocal.plugin]
});
