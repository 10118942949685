import axios from "axios";
import { getApiUrl } from "@/utils/config";
import { buildHttpHeader, handleServerError } from "@/utils/utils";
import store from "../store";

const getLocations = () => {
  const headers = buildHttpHeader();
  return axios
    .get(`${getApiUrl()}/UAMS/apm/permissions`, {
      headers
    })
    .then(response => response.data.permissions)
    .catch(err => {
      if (err.response?.status === 400) {
        store.commit("showError", {
          show: true,
          title: "Error",
          code: 400,
          message:
            "Please clear browser cookies or run MSA in Incognito/InPrivate mode. If the issue persists, please contact IDM support. "
        });
      }
      handleServerError(err.response);
    });
};
const getAttestationWindowAtLocation = locationId => {
  if (!locationId) {
    return new Promise(function(resolve) {
      resolve({});
    });
  }

  let locationIdWithoutNG = String(locationId).replace("NG", "");
  const url = `${getApiUrl()}/UAMS/attestation/window/${locationIdWithoutNG}`;
  return axios
    .get(url, {
      headers: buildHttpHeader()
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      handleServerError(err.response);
    });
};

const clearPermissionsCache = () => {
  return axios
    .delete(`${getApiUrl()}/UAMS/apm/clearPermissions`, {
      headers: buildHttpHeader()
    })
    .catch(err => {
      handleServerError(err.response);
    });
};
export default {
  getLocations,
  getAttestationWindowAtLocation,
  clearPermissionsCache
};
