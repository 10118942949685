import { debounce } from "@/utils/utils";

export default {
  methods: {
    onSearchChangeDebounced: debounce(function (value) {
      const meta = this.$router.currentRoute.meta;
      const mutator = meta && meta.searchMutator;
      if (mutator) {
        this.$store.commit(mutator, value);
      }
    }, 300)
  }
}
