import store from "../store/";
import Vue from "vue";

// filters
import Initials from "@/filters/initials";
import Csv from "@/filters/csv";
import Capitalise from "@/filters/capitalise";
import Possessive from "@/filters/possessive";
import { date, time, timeDate } from "@/filters/date";
import { DET_ROLES } from "@/utils/constants";

const debounce = (func, wait, immediate) => {
  let timeout;
  return function() {
    let context = this,
      args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const parseJwt = token => {
  var base64Url = token.split(".")[1];
  var base64 = decodeURIComponent(
    atob(base64Url)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(base64);
};

const buildHttpHeader = header => {
  return Vue.prototype.$OAuth.buildHttpHeader(header);
};

const handleServerError = response => {

  if (!response) {
    store.commit("showError", {
      show: true,
      code: -1,
      message: "An unspecified error occured."
    });
    throw "An unspecified error occured.";
  }

  // error codes defiend here: https://itdwiki.det.nsw.edu.au/display/idm/Access+Management+-+UAMS
  const unauthorisedCodes = [
    "MSA-101",
    "MSA-102",
    "MSA-103",
    "MSA-104",
    "MSA-105",
    "MSA-106",
    "MSA-107",
    "MSA-108",
    "MSA-109",
    "MSA-111",
    "MSA-112"
  ];
  const redirectLoginCodes = ["MSA-110"];
  const swallowCodes = ["MSA-119"];

  if (response.data && response.data.errorCode) {
    if (swallowCodes.indexOf(response.data.errorCode) !== -1) {
      return;
    }

    let serverMessage = response.data.errorMessage;
    if (serverMessage && serverMessage[serverMessage.length - 1] !== ".") {
      serverMessage += ".";
    }

    if (redirectLoginCodes.indexOf(response.data.errorCode) !== -1) {
      // token expired
      store.commit("showError", {
        show: true,
        code: response.data.errorCode,
        message: "Session expired.",
        callback: function() {
          window.location.href = "/login";
        }
      });
    }
    if (unauthorisedCodes.indexOf(response.data.errorCode) !== -1) {
      store.commit("showError", {
        show: true,
        title: "Unauthorised",
        code: response.data.errorCode,
        message: serverMessage
      });
      throw serverMessage;
    }

    /* Translating unhandled error messages from backend - If we see null or NullPointer then change it to something more user friendly */
    if (serverMessage != null && serverMessage.toLowerCase().includes("null")) {
        serverMessage = "Encountered an error. If this problem persists please call EDConnect on '1300 32 32 32' and report an incident."
    }

    // throw any others
    store.commit("showError", {
      show: true,
      title: "Error",
      code: response.data.errorCode,
      message: serverMessage
    });
    throw serverMessage;
  }

  //console.log("Tying to extract response when response.data.message exist: " + JSON.stringify(response));
  if (response.data && response.data.message && response.status) {
      store.commit("showError", {
        show: true,
        code: response.status,
        message: response.data.message
      });
      throw response.data.message;
  }

  if (response.status && response.statusText) {
    store.commit("showError", {
      show: true,
      code: response.status,
      message: response.statusText
    });
    throw response.statusText;
  }

};

const isInternetExplorer = () => document.documentMode != null;

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const filters = {
  initials: Initials,
  csv: Csv,
  capitalise: Capitalise,
  possessive: Possessive,
  date,
  time,
  timeDate
};

const wait = time =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });

const removeSpacesAndLowercase = str => {
  const stringWithoutSpaces = str.replace(/\s+/g, "");
  return (
    stringWithoutSpaces.charAt(0).toLowerCase() + stringWithoutSpaces.slice(1)
  );
};

const isTarget = (roles, target) => {
  return roles.indexOf(target) !== -1 || roles === target;
};

const isPrincipalRole = roles => {
  return isTarget(roles, DET_ROLES.PRINCIPAL);
};
const isTeacher = roles => {
  return isTarget(roles, DET_ROLES.TEACHER);
};
const isCorporate = roles => {
  return isTarget(roles, DET_ROLES.CORPORATE);
};
const isContractor = roles => {
  return isTarget(roles, DET_ROLES.CONTRACTOR);
};

// Function to clear all cookies for the current domain
const clearAllCookies = () => {
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie =
      name +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=" +
      window.location.hostname +
      ";path=/";
  }
};

export {
  debounce,
  parseJwt,
  buildHttpHeader,
  handleServerError,
  isInternetExplorer,
  sleep,
  filters,
  wait,
  removeSpacesAndLowercase,
  isPrincipalRole,
  isTeacher,
  isCorporate,
  isContractor,
  clearAllCookies
};
