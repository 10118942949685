import Vue from "vue";

const getElementsByTagNames = (tags, rootElement) => {
  const scope = rootElement;
  var elements = [];
  for (const t of tags) {
    const elementsByTag = [...scope.getElementsByTagName(t)];
    elements = elements.concat(
      elementsByTag.filter(e => {
        return e.offsetParent != null;
      })
    );
  }
  return elements.sort((e, e1) =>
    (e.compareDocumentPosition(e1) & 4) === 4 ? -1 : 1
  );
};

Vue.directive("adsTrapFocus", {
  bind: function(el) {
    setTimeout(function() {
      const inputs = getElementsByTagNames(
        ["select", "input", "textarea", "button", "a"],
        el
      );
      const firstInput = inputs[0];

      // last input might be disabled so append a known element that we can wrap tabbing around
      const anchorEl = document.createElement("div");
      anchorEl.setAttribute("tabIndex", "0");
      anchorEl.setAttribute("id", "modal-end-of-modal-anchor");
      anchorEl.setAttribute(
        "style",
        "border: 0; clip: rect(0 0 0 0); height: 1px; width: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; right: 1px; bottom: 1px"
      );
      anchorEl.innerText = "End of dialog";
      el.appendChild(anchorEl);

      if (anchorEl) {
        /* redirect last tab to first input*/
        anchorEl.addEventListener("keydown", function(e) {
          if (e.key === "Tab" && !e.shiftKey) {
            e.preventDefault();
            firstInput.focus();
          }
        });
      }

      if (firstInput) {
        /*redirect first shift+tab to last input*/
        firstInput.addEventListener("keydown", function(e) {
          if (e.key === "Tab" && e.shiftKey) {
            e.preventDefault();
            anchorEl.focus();
          }
        });
      }
    }, 500);
  }
});

Vue.directive("adsSetFocus", {
  bind: function(el, binding) {
    const initialFocusElementId = binding.arg;
    const boundValue = binding.value;
    const defaultOptions = {
      timeout: 350
    };
    const options = Object.assign({}, defaultOptions, boundValue);

    setTimeout(() => {
      if (initialFocusElementId) {
        const el = document.getElementById(initialFocusElementId);
        if (el) {
          // set focus on primary action
          el.focus();
        }
        return;
      }
      const inputs = getElementsByTagNames(
        ["select", "input", "textarea", "button", "a"],
        el
      );
      if (inputs.length) {
        inputs[0].focus;
      }
    }, options.timeout);
  }
});
