import Vue from "vue";
import delegatesApi from "api/delegates";
import { searchFilterContains } from "@/utils/filters";

const delegateAppsModule = {
  state: {
    delegateAppFilter: "",
    delegatableApps: {}
  },
  getters: {
    delegatableApplications: state => (locationId, staffId) => {
      // ignore type on location id!
      //console.log("Full list of delegatable apps as of now from delegatableApplications: "+ JSON.stringify(state.delegatableApps));
      const apps =
        state.delegatableApps.locationId === locationId &&
        state.delegatableApps.staffId === staffId
          ? state.delegatableApps.apps
          : [];
      return state.delegateAppFilter
        ? apps.filter(a =>
            searchFilterContains([a.name], state.delegateAppFilter)
          )
        : apps;
    },
    unfilteredDelegatableApplications: state => (locationId, staffId) => {
      return state.delegatableApps.locationId === locationId &&
        state.delegatableApps.staffId === staffId
        ? state.delegatableApps.apps
        : [];
    },
    delegateAppFilter: state => state.delegateAppFilter
  },
  mutations: {
    setDelegatableApps(state, { locationId, staffId, apps }) {
      // ensure all apps have a delegated property so they are observed correctly
      const appsFilled = apps.map(app => {
        app.provisioned = app.provisioned || false;
        return app;
      });
      state.delegatableApps = { locationId, staffId, apps: appsFilled };
    },
    setDelegatableAppsFilter(state, filter) {
      state.delegateAppFilter = filter;
    },
    setDelegateableAppMeta(
      state,
      { applications, applicationCode, metaId, value }
    ) {
      const app = applications.find(a => a.code === applicationCode);
      Vue.set(app, metaId, value);
    }
  },
  actions: {
    async fetchAppsForDelegateAtLocation(
      { commit, dispatch, getters },
      { locationId, locType, staffId }
    ) {
      commit("setIsLoading", true);

      // fetch staff, location, and apps if not already in store
      if (!getters.locations.length) {
        await dispatch("fetchLocations");
      }

      if (
        !getters.delegatesByLocation ||
        !getters.delegatesByLocation(locationId)
      ) {

        //console.log("Before calling fetchDelegatesForLocation from delegateApps store: locType-" + locType);
        await dispatch("fetchDelegatesForLocation",  {
            locationId: locationId,
            locType: locType
            });
      }

      let delegate = getters.delegate(locationId, staffId);
      if (!delegate) {
        // Not in delegate list. Get staff from staff list
        if (!getters.staffByLocation || !getters.staffByLocation(locationId)) {
          //console.log("Before calling fetchStaffForLocation from delegateApps: locType-" + locType);
          await dispatch("fetchStaffForLocation", {
                locType: locType,
                locationId: locationId
                });
        }
        delegate = getters.staff(locationId, staffId);
      }

      if (!delegate) {
        window.location.href = "/404";
      }

      return await delegatesApi
        .getAppsForDelegateAtLocation(locationId, locType, staffId, delegate.netRegId)
        .then(response => {
          return commit("setDelegatableApps", {
            locationId,
            staffId,
            apps: response
          });
        })
        .finally(() => commit("setIsLoading", false));
    },

    delegateApp(
      { commit, getters, dispatch },
      {
        locationId,
        locType,
        locationName,
        detUserId,
        staffId,
        staffRole,
        applicationId,
        applicationTaskId,
        applicationCode,
        enabledTasks,
        provision,
        provisionAll,
        revokeAll
      }
    ) {
      //console.log("Entered delegateApp with locType-" + locType + " applicationCode-" + applicationCode + " locationId-" + locationId);
      const applications = getters.delegatableApplications(
        locationId,
        detUserId
      );

      //console.log("Before calling provisionAppDelegation from delegateApp: applications-" + JSON.stringify(applications));

      // block ui - back-end dev wanted one app delegation at a time
      commit("setIsLoading", true);
      commit("showSnackbar", { type: "success", show: false });

      const toggledApp = applications.find(a => a.code === applicationCode);
      toggledApp.provisioned = provision;

      delegatesApi
        .provisionAppDelegation(
          locationId,
          locType,
          locationName,
          detUserId,
          staffId,
          staffRole,
          applicationId,
          applicationTaskId,
          enabledTasks,
          provision,
          provisionAll,
          revokeAll
        )
        .then(
          () => {
            commit("setDelegateableAppMeta", {
              applications,
              applicationCode,
              metaId: "hasError",
              value: false
            });

            if (revokeAll) {
              dispatch("removeDelegateLocal", { locationId, detUserId });
            } else {
              // ensure they delegate is up to date
              dispatch("updateDelegateLocal", {
                locationId,
                detUserId,
                fullAccess: provisionAll
              });
            }

            const delegationType = provision ? "granted" : "removed";
            commit("showSnackbar", {
              text:
                "Application delegation permission has been " +
                delegationType +
                ".",
              type: "success",
              show: true
            });
          },
          e => {
            toggledApp.provisioned = !provision;
            commit("setDelegateableAppMeta", {
              applications,
              applicationCode,
              metaId: "hasError",
              value: true
            });
            commit("showSnackbar", {
              text: `This attempt was unsuccessful. ${e}`,
              type: "error",
              show: true
            });
          }
        )
        .finally(() => {
          commit("setIsLoading", false);
        });
    },
    delegateAllApps(
      { commit, getters, dispatch },
      {
        locationId,
        locType,
        locationName,
        detUserId,
        staffId,
        staffRole,
        applicationId,
        applicationTaskId,
        enabledTasks,
        provision,
        provisionAll,
        revokeAll
      }
    ) {
      const applications = getters.delegatableApplications(
        locationId,
        detUserId
      );

      commit("setIsLoading", true);
      commit("showSnackbar", { type: "success", show: false });

      //console.log("Before calling provisionAppDelegation from delegateAllApps: locType-" + locType);
      delegatesApi
        .provisionAppDelegation(
          locationId,
          locType,
          locationName,
          detUserId,
          staffId,
          staffRole,
          applicationId,
          applicationTaskId,
          enabledTasks,
          provision,
          provisionAll,
          revokeAll
        )
        .then(
          () => {
            for (let i = 0; i < applications.length; i++) {
              Vue.set(applications[i], "provisioned", provision);
              Vue.set(applications[i], "hasError", false);
            }

            if (revokeAll) {
              dispatch("removeDelegateLocal", { locationId, detUserId });
            } else {
              // ensure they delegate is up to date
              dispatch("updateDelegateLocal", {
                locationId,
                detUserId,
                fullAccess: provisionAll
              });
            }

            const delegationType = provision ? "granted" : "removed";
            commit("showSnackbar", {
              text: `Delegation to ${detUserId} for all apps has been ${delegationType}.`,
              type: "success",
              show: true
            });
          },
          e => {
            for (let i = 0; i < applications.length; i++) {
              Vue.set(applications[i], "hasError", true);
            }
            commit("showSnackbar", {
              text: `This attempt was unsuccessful. ${e}`,
              type: "error",
              show: true
            });
          }
        )
        .finally(() => {
          commit("setIsLoading", false);
        });
    }
  }
};

export default delegateAppsModule;
