<script>
import { mapGetters } from "vuex";
import { STAFF_TYPES } from "@/utils/constants";
import { UI_ACTIONS } from "../utils/constants";

export default {
  name: "MsaSchoolPicker",
  props: {
    locations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      locationId: "",
      locType: "",
      fakeElRef: "fakeEl",
      schoolPickerRef: "schoolSelection"
    };
  },
  computed: {
    ...mapGetters({
      requestsByLocation: "requestsByLocation",
      selectedSchoolId: "selectedSchoolId",
      selectedLocType: "selectedLocType",
      selectedAppId: "selectedAppId",
      school: "school",
      fnActionByName: "getActionByName",
      isGov: "isGov"
    }),
    authLoaded() {
      return this.$OAuth?.loaded;
    }
  },
  watch: {
    authLoaded: {
      immediate: true,
      handler(loaded) {
        if (!loaded) return;
        this.$store.commit("setUserProfile", this.$OAuth?.data.profile);
        if (!this.locations.length) {
          return this.$store.dispatch("fetchLocations").then(async () => {
            if (this.locations.length) {
              return await this.init();
            }
            this.$store.commit("showError", {
              show: true,
              message: `No school locations were returned for ${this.profile
                .given_name +
                " " +
                this.profile.sn}.`
            });
            //console.log("Locations when loading SchoolPicker :" + JSON.stringify(this.locations));
            this.$store.commit("setIniDataLoaded", true);
          });
        }
        this.init();
      }
    },
    selectedSchoolId: {
      immediate: true,
      handler(id) {
        if (!id) return;
        this.adjustSchoolSelectWidth(id);
        this.$store.commit("setAttestationWindow", { attestationWindow: {} });
        if (
          this.fnActionByName(UI_ACTIONS.ANNUAL_ATTESTATION) &&
          this.isGov(id)
        ) {
          return this.$store.dispatch("fetchAttestationWindow", {
            locationId: id
          });
        }
        this.$store.commit("setStaffType", STAFF_TYPES.internal);
      }
    }
  },
  methods: {
    async init() {
      try {
        let initLocationId =
          this.$route.query.locationId ||
          localStorage.getItem("defaultSchoolId");
          //console.log("Determined existing locationId or defaultSchoolId as:"+initLocationId);

        let locType='';
        if (!this.$store.getters.school(initLocationId)) {
          initLocationId = this.locations[0].id;
          locType = this.locations[0].locType;
          //console.log("Could not find existing location hence defaulting to first location:"+initLocationId);
        }

        locType = this.locations.find(s => s.id === initLocationId).locType;
        this.locType = locType;
        this.locationId = initLocationId;

        await this.$store.commit("setSelectedLocType", locType);
        await this.$store.commit("setSelectedSchoolId", initLocationId);

        //console.log("full Locations from init of SchoolPicker :" + JSON.stringify(this.locations));
        //console.log("Inside init : locId-" + this.locationId);
        //console.log("Inside init : locType-" + this.locType);
        this.initData();
        this.$store.commit("setIniDataLoaded", true);
      } catch (error) {
        throw new Error("An error occurred: " + error.message);
      }
    },
    async initData() {
      try {
        if (this.locationId !== null && this.locationId !== "" && this.locType !== "") {

          //console.log("Inside initData : locType-" + this.locType);
          await this.fetchStaffForSchool(this.locationId, this.locType);
          this.$store.dispatch("fetchPendingHistoryCount", this.locationId);
        }
      } catch (error) {
        throw new Error("An error occurred: " + error.message);
      }
    },
    getNewPath(currentPath, rootPath) {
      if (rootPath === "provision") {
        return currentPath.includes("staff-view")
          ? `/${rootPath}/staff-view`
          : `/${rootPath}/app-view`;
      }
      return `/${rootPath}`;
    },
    async changeSchool(schoolId) {

      let locType = this.locations.find(s => s.id === schoolId).locType;

      //console.log("changeSchool called: locId-" + schoolId);
      //console.log("changeSchool called: locType-" + locType);

      await this.$store.commit("setSelectedSchoolId", schoolId);
      await this.$store.commit("setSelectedLocType", locType);

      this.locationId = schoolId;
      this.locType = locType;

      await this.initData();
      if (this.selectedAppId) {
        this.$store.commit("setSelectedAppId", "");
        this.$store.commit("resetStaffAccess");
        this.$store.commit("setIsApp", true);
      }
      const currentPath = this.$router.currentRoute.path;
      const rootPath = currentPath.split("/")[1] || "";
      const newPath = this.getNewPath(currentPath, rootPath);

      if (newPath !== currentPath) {
        this.$router.push({ path: newPath });
      }
    },
    async fetchStaffForSchool(locationId, locType) {
      try {
        if (this.isAnnualAttestationPage()) {
          this.triggerStaffListReload();
          return;
        }
        //console.log("Before calling fetchStaffForLocation from fetchStaffForSchool : locType-" + locType);
        await this.fetchStaffForLocation(locationId, locType);
      } catch (error) {
        throw new Error("An error occurred: " + error.message);
      }
    },

    isAnnualAttestationPage() {
      return this.$router.history.current.name === "annualAttestation";
    },

    triggerStaffListReload() {
      this.$store.commit("setReloadStaffListRequired");
    },

    async fetchStaffForLocation(locationId, locType) {
      //console.log("Inside fetchStaffForLocation from MSASchoolPicker : locType-" + locType);
      await this.$store.dispatch("fetchStaffForLocation", {
        locType: locType,
        locationId: locationId
      });
    },

    adjustSchoolSelectWidth(id) {
      if (this.$vuetify.breakpoint.mobile) return;
      const fakeEl = this.$refs[this.fakeElRef];
      const schoolSelection = this.$refs[this.schoolPickerRef].$refs["input"];
      const originalStyle = window.getComputedStyle(schoolSelection);
      const fakeElStyle = fakeEl.style;
      const widthRelatedStyles = [
        "fontFamily",
        "fontSize",
        "fontStyle",
        "fontWeight",
        "letterSpacing",
        "textTransform",
        "borderLeftWidth",
        "borderRightWidth",
        "paddingLeft",
        "paddingRight"
      ];
      widthRelatedStyles.forEach(style => {
        fakeElStyle[style] = originalStyle[style];
      });
      fakeEl.innerHTML = this.school(id).name;
      const fakeEleStyles = window.getComputedStyle(fakeEl);
      schoolSelection.style.width = fakeEleStyles.width;
    }
  }
};
</script>

<template>
  <v-container id="app-bar--location-container">
    <label
      id="app-bar--select-schoool-label"
      class="visuallyhidden"
      for="app-bar--school-select"
    >
      Your Schools
    </label>
    <v-autocomplete
      id="app-bar--school-select"
      :ref="schoolPickerRef"
      v-model="locationId"
      :items="locations"
      :menu-props="{ contentClass: 'school-picker-menu' }"
      aria-labelledby="app-bar--select-schoool-label"
      hide-details
      item-text="name"
      item-value="id"
      placeholder="Select School..."
      solo
      v-bind="{ flat: $vuetify.breakpoint.mdAndDown }"
      @change="changeSchool"
    />
    <div :ref="fakeElRef" class="fake-el" />
  </v-container>
</template>

<style lang="scss" scoped>
.fake-el {
  position: absolute;
  top: 0;
  left: -9999px;
  overflow: hidden;
  visibility: hidden;
  white-space: nowrap;
  height: 0;
}
</style>
