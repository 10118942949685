import locationsApi from "api/locations";
import capitalise from "../../filters/capitalise";

const locationModule = {
  state: {
    locations: [],
    selectedSchoolId: "",
    selectedLocType: "",
    actions: [],
    isSuperAdmin: false,
    attestationWindow: {}
  },
  getters: {
    selectedSchoolId: state => state.selectedSchoolId,
    selectedLocType: state => state.selectedLocType,
    actions: state => state.actions,
    selectedSchoolName: state => {
      const school = state.locations.find(s => s.id === state.selectedSchoolId);
      return school ? school.name : "";
    },
    locations: state => state.locations,
    isSuperAdmin: state => state.isSuperAdmin,
    isGov: state => locationId =>
      state.locations.find(s => s.id === locationId).isGov,
    school: state => locationId => {
      //console.log("List of all locations: " + JSON.stringify(state.locations));
      return state.locations.find(s => s.id === locationId)
      },

    getActionByName: state => actionName =>
      state.actions.find(action => action === actionName),
    attestationWindowStatus: state => state.attestationWindow.status
  },
  mutations: {
    setLocations(state, { locations }) {
      state.locations = locations;
      //console.log("Locations from the store: locations-"+ JSON.stringify(state.locations));
    },
    setAttestationWindow(state, { attestationWindow }) {
      state.attestationWindow = attestationWindow;
    },
    setSuperAdmin(state, isSuperAdmin) {
      state.isSuperAdmin = isSuperAdmin;
    },
    setLeagalUIActons(state, actions) {
      state.actions = actions;
    },
    setSelectedLocType(state, locType) {
      state.selectedLocType = locType;
    },
    setSelectedSchoolId(state, locationId) {
      //console.log("Entered setSelectedSchoolId in locations store: locationId-" + locationId);
      if (locationId === "") {
        return;
      }
      localStorage.setItem("defaultSchoolId", locationId);
      state.selectedSchoolId = locationId;
      if (!state.locations || !state.locations.length) {
        localStorage.removeItem("defaultSchoolId");
        state.selectedSchoolId = null;
      } else if (!state.selectedSchoolId) {
        //console.log("Defaulting the locationId to the first location")
        state.selectedSchoolId = state.locations[0].id;
      }
    }
  },
  actions: {
    async fetchLocations({ commit }) {
      commit("setIsLoading", true);
      return await locationsApi
        .getLocations()
        .then(response => {
          const allSchools = [
            ...(response.locations ?? []).map(l => ({
              ...l,
              id: `${l.id}`, //for string value
              name: `${capitalise(l.name)} - ${l.id}`,
              isGov: true,
              locType: "GovLocation"
            })),
            ...(response.ngLocations ?? []).map(l => ({
              ...l,
              id: `${l.id}NG`,
              name: `(NG) ${capitalise(l.name)} - ${l.id}`,
              isGov: false,
              locType: "NonGovLocation"
            }))
          ];

          //console.log("Locations when loading permissions :" + JSON.stringify(allSchools));
          commit("setLocations", { locations: allSchools });
          const isSuperAdmin =
            response.locations?.length > 0 && response.ngLocations?.length > 0;
          commit("setSuperAdmin", isSuperAdmin);
          commit("setLeagalUIActons", response.legalUIActions);
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    },
    async fetchAttestationWindow({ commit }, { locationId }) {
      commit("setIsLoading", true);
      return await locationsApi
        .getAttestationWindowAtLocation(locationId)
        .then(response => {
          commit("setAttestationWindow", { attestationWindow: response });
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    }
  }
};

export default locationModule;
