import Vue from "vue";
import VueRouter from "vue-router";
import Staff from "../views/Home.vue";
import Provision from "@/views/Provision.vue";

const AddApplication = () => import("../views/AddApplication");
const Delegates = () => import("../views/Delegates");
const Delegate = () => import("../views/Delegate");
const Settings = () => import("../views/Settings");
const PendingRequests = () => import("../views/PendingRequests");
const RequestHistory = () => import("../views/RequestHistory");
const EmailActionApprove = () => import("../views/EmailLanding/Approve");
const EmailActionDeny = () => import("../views/EmailLanding/Deny");
const NotFound = () => import("../views/NotFound");
const AppViewProvisioning = () => import("@/views/ProvisionAppView.vue");
const StaffApps = () => import("../views/Staff.vue");
const Application = () => import("../views/Application");

const VerifyAccess = () => import("../views/VerifyAccess");
const AnnualAttestation = () => import("../views/AnnualAttestation");

Vue.use(VueRouter);

const provisionRoutes = [
  {
    path: "/",
    redirect: "/provision/staff-view"
  },
  {
    path: "/provision",
    name: "provision",
    component: Provision,
    children: [
      {
        path: "staff-view",
        name: "staff-view",
        component: Staff,
        meta: {
          title: "Manage Staff Access",
          searchMutator: "setStaffFilter",
          banner: {
            icon: "mdi-account-multiple-outline",
            heading: "Manage Staff Access",
            subtitle: "Select any staff member to view the apps they can access"
          }
        }
      },
      {
        path: "staff-view/:staffId",
        name: "staffApps",
        component: StaffApps,
        props: true,
        meta: {
          title: "Staff",
          canGoBack: true,
          searchMutator: "setAppFilter",
          mobileAppTitle: "$staffPossessive Applications",
          banner: {
            icon: "mdi-account-multiple-outline",
            heading: "Manage Staff Access",
            subtitle:
              "Select any app from the list to edit the staff member's access"
          }
        }
      },
      {
        path: "staff-view/:staffId/attestation",
        name: "staffAppsAttestation",
        component: StaffApps,
        props: true,
        meta: {
          title: "Staff Attestation",
          canGoBack: true,
          searchMutator: "setAppFilter",
          mobileAppTitle: "Staff Attestation",
          banner: {
            icon: "mdi-account-multiple-outline",
            heading: "Annual Attestation",
            subtitle: "Edit the staff member's access and confirm attestation"
          }
        }
      },
      {
        path: "staff-view/:staffId/application/:applicationCode",
        name: "application",
        component: Application,
        props: true,
        meta: {
          title: "Staff Applications",
          canGoBack: true,
          mobileAppTitle: "App Permissions",
          banner: {
            icon: "mdi-account-multiple-outline",
            heading: "Manage Staff Access",
            subtitle: "Add this staff member's access to the tasks listed"
          }
        }
      },
      {
        path: "staff-view/:staffId/addapplication",
        name: "addApplication",
        component: AddApplication,
        props: true,
        meta: {
          title: "Add Application",
          canGoBack: true,
          searchMutator: "setAppFilter",
          mobileAppTitle: "Add apps for $staff",
          banner: {
            icon: "mdi-account-multiple-outline",
            heading: "Manage Staff Access",
            subtitle:
              "Select any app from the list to add the staff member's access"
          }
        }
      },
      {
        path: "staff-view/:staffId/verify-access",
        name: "verifyAccess",
        component: VerifyAccess,
        props: true,
        meta: {
          title: "Verify Access",
          canGoBack: true,
          searchMutator: "setAppFilter",
          mobileAppTitle: "Verify Access",
          banner: {
            icon: "mdi-account-multiple-outline",
            heading: "Verify Staff Access",
            subtitle: `Staff App access is controlled by multiple systems, synchronisation issues will be displayed below. 
            Use the ‘Repair Access’ button to clear the fault - if the problem is not rectified contact EDConnect (1300 32 32 32).`
          }
        }
      },
      {
        path: "app-view",
        name: "app-view",
        component: AppViewProvisioning,
        meta: {
          title: "Manage Staffs Access",
          canGoBack: false,
          searchMutator: "setStaffFilter",
          banner: {
            icon: "mdi-account-multiple-outline",
            heading: "Manage Staff Access",
            subtitle: "Select any application to view/edit the staff access"
          }
        }
      }
    ]
  }
];

const attestationRouts = [
  {
    path: "/annual-attestation",
    name: "annualAttestation",
    component: AnnualAttestation,
    meta: {
      title: "Annual Attestation",
      searchMutator: "setDelegatesFilter",
      banner: {
        icon: "mdi-account-eye-outline",
        heading: "Annual Attestation",
        subtitle: "Confirm, remove or modify the staff access"
      }
    }
  }
];

const delegateRoutes = [
  {
    path: "/delegates",
    name: "delegates",
    component: Delegates,
    meta: {
      title: "Manage Staff Access",
      searchMutator: "setDelegatesFilter",
      banner: {
        icon: "mdi-calendar-account-outline",
        heading: "Manage Delegates",
        subtitle:
          "Nominate a staff member to manage application access on your behalf"
      }
    }
  },
  {
    path: "/delegates/:staffId",
    name: "delegate",
    component: Delegate,
    props: true,
    meta: {
      title: "Delegate",
      canGoBack: true,
      searchMutator: "setDelegatableAppsFilter",
      banner: {
        icon: "mdi-calendar-account-outline",
        heading: "Manage Delegate",
        subtitle: "Select the applications delegated to this staff member"
      }
    }
  }
];
const settingsRoute = {
  path: "/settings",
  name: "settings",
  component: Settings,
  meta: {
    title: "Settings",
    banner: {
      icon: "mdi-cog-outline",
      heading: "General Settings",
      subtitle: "Set request"
    }
  }
};
const requestRoutes = [
  {
    path: "/pendingRequests",
    name: "pendingRequests",
    component: PendingRequests,
    meta: {
      title: "Pending Requests",
      searchMutator: "setPendingRequestsFilter",
      banner: {
        icon: "mdi-key-outline",
        heading: "Manage Access Requests",
        subtitle: "Review your incoming application access requests below"
      }
    }
  },
  {
    path: "/requesthistory",
    name: "requesthistory",
    component: RequestHistory,
    meta: {
      title: "Request History",
      searchMutator: "setRequestHistoryFilter",
      banner: {
        icon: "mdi-history",
        heading: "Request History",
        subtitle: "Previous access requests"
      }
    }
  }
];
const emailActionRoutes = [
  {
    path: "/approve",
    name: "approveRequest",
    component: EmailActionApprove,
    props: true,
    meta: {
      title: "Approve Request"
    }
  },
  {
    path: "/deny",
    name: "denyRequest",
    component: EmailActionDeny,
    props: true,
    meta: {
      title: "Deny Request"
    }
  }
];
const routes = [
  ...provisionRoutes,
  ...delegateRoutes,
  ...requestRoutes,
  ...emailActionRoutes,
  ...attestationRouts,
  settingsRoute,
  {
    path: "*",
    name: "notFound",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const provisionParentRoute = to.path === "/provision";
  provisionParentRoute ? next(false) : next();
});

export default router;
