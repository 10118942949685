export const DET_ROLES = {
  PRINCIPAL: "SCHOOL.PRINCIPAL",
  TEACHER: "SCHOOL.TEACHER",
  CORPORATE: "SCHOOL.OTHERCORPORATE",
  CONTRACTOR: "DET.CONTRACTOR"
};

export const USER_ROLES = {
  PRINCIPAL: "Principal",
  TEACHER: "Teacher",
  CORPORATE: "Other Corporate",
  CONTRACTOR: "Contractor",
  STAFF: "Staff"
};
export const UI_ACTIONS = {
  DELEGATE_APP: "DELEGATE_APPLICATION",
  UNDELEGATE_APP: "UNDELEGATE_APPLICATION",
  ADD_DELEGATE: "ADD_DELEGATE",
  DELETE_DELEGATE: "DELETE_DELEGATE",
  LIST_DELEGATE_APPS: "LIST_DELEGATE_APPLICATIONS",
  LIST_DELEGATES: "LIST_DELEGATES",
  LIST_PREFERENCES: "LIST_PREFERENCES",
  LIST_PENDING_REQUESTS: "LIST_PENDING_REQUESTS",
  LIST_REQUEST_HISTORY: "LIST_REQUEST_HISTORY",
  LIST_APPLICATIONS: "LIST_APPLICATIONS",
  LIST_GUEST_STAFF: "LIST_GUEST_STAFF",
  LIST_STAFF: "LIST_STAFF",
  DEPROVISION_APPLICATION: "DEPROVISION_APPLICATION",
  PROVISION_APPLICATION: "PROVISION_APPLICATION",
  APPROVE_APPLICATION: "APPROVE_APPLICATION",
  UPDATE_PREFERENCES: "UPDATE_PREFERENCES",
  ANNUAL_ATTESTATION: "ANNUAL_ATTESTATION"
};
export const PROVISION_MENU_ITEMS = [
  {
    id: "nav-bar--staffs-access",
    title: "Provision",
    icon: "mdi-account-multiple-outline",
    iconOutlined: true,
    route: "/provision",
    items: [
      {
        id: "nav-bar--staffs-access-user",
        title: "Staff view",
        route: "/provision/staff-view"
      },
      {
        id: "nav-bar--staffs-access-app",
        title: "Application view",
        route: "/provision/app-view"
      }
    ]
  }
];
export const DELEGATION_MENU_ITEMS = {
  id: "nav-bar--delegates",
  title: "Delegate",
  icon: "mdi-calendar-account-outline",
  iconOutlined: true,
  route: "/delegates"
};
export const SETTING_MENU_ITEMS = {
  id: "nav-bar--settings",
  title: "Settings",
  icon: "mdi-cog-outline",
  route: "/settings"
};
export const PENDING_REQUEST_MENU_ITEMS = {
  id: "nav-bar--pending-requests",
  title: "Pending Requests",
  icon: "mdi-key-outline",
  route: "/pendingRequests"
};
export const REQUEST_HISTORY_MENU_ITEMS = {
  id: "nav-bar--request-history",
  title: "Request History",
  icon: "mdi-history",
  route: "/requesthistory"
};

export const ANNUAL_ATTESTATION_MENU_ITEMS = {
  id: "nav-bar--annual-attestation",
  title: "Annual Attestation",
  icon: "mdi-account-eye-outline",
  route: "/annual-attestation"
};

export const LEAN_APPLICATIONS = new Set([
  44,
  53,
  13,
  4,
  50,
  54,
  51,
  29,
  32,
  5,
  57,
  26,
  3,
  45,
  31,
  58,
  11,
  47,
  46,
  52,
  56,
  55
]);
export const FIT_APPLICATIONS = new Set([40, 43, 42, 59]);
export const FAT_APPLICATIONS = new Set([20, 6, 15, 34, 38]);

export const STAFF_TYPES = {
  internal: "Internal",
  external: "External"
};
export const PAGE_FORMATS = {
  multi: "Multi",
  single: "Single"
};

export const PAGE_FORMATS_OBJ = [
  {
    label: "Multi Pages",
    value: "Multi"
  },
  {
    label: "Single Page",
    value: "Single"
  }
];
export const STAFF_TYPES_OBJ = [
  { label: "Internal Staff", value: "Internal" },
  { label: "External Staff", value: "External" }
];

export const PAGE_SIZE_OBJ = [
  { label: "20 Per Page", value: 10 },
  { label: "50 Per Page", value: 20 },
  { label: "100 Per Page", value: 50 }
];

export const AG_ALPINE_THEME = {
  light: "ag-theme-alpine",
  dark: "ag-theme-alpine-dark"
};

export const GRID_PAGE_SIZE = {
  small: 10,
  medium: 20,
  large: 50
};

export const MSA_CLIENT_ERROR = {
  shortNameMissing:
    "If this problem persists please call EDConnect on '1300 32 32 32' and quote the following for reference: 'Short name cannot be found'. You may wish to write this down now, or take a screen capture.",
  isGuest:
    "Can't modify access. Guest user have default access to Schools OASIS Archive Application.",
  isStaff:
    "Can't modify access. Location staff have default access to Portal Resources.",
  netRegIdMissing: "Staff netRegId is missing."
};

export const DIALOG_ACTIONS = [
  { name: "Cancel", type: "tertiary" },
  { name: "Yes", type: "primary" }
];

export const AG_KEY = "Using_this_{AG_Grid}_Enterprise_key_{AG-057227}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Department_of_Education}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Manage_Staff_Access}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Manage_Staff_Access}_need_to_be_licensed___{Manage_Staff_Access}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{22_April_2025}____[v3]_[01]_MTc0NTI3NjQwMDAwMA==9ca97be36b9fc537cab7380906497cf4";
export const NODE_ENV = "production";
export const ENV_URL = "/envmetadata";
export const VUE_APP_OAUTH_CLIENT_ID = "VUE_APP_OAUTH_CLIENT_ID";
export const VUE_APP_OAUTH_URL = "VUE_APP_OAUTH_URL";
export const VUE_APP_TOKEN_URI = "VUE_APP_TOKEN_URI";
export const VUE_APP_OAUTH_LOGOUT_URL = "VUE_APP_OAUTH_LOGOUT_URL";
export const VUE_APP_OAUTH_REDIRECT_URL = "VUE_APP_OAUTH_REDIRECT_URL";
export const APP_SELECTOR = "#msa-app";
export const VUE_APP_AG_GRID_ENTERPRISE = "VUE_APP_AG_GRID_ENTERPRISE";
