import Vue from "vue";
import requestsApi from "api/requests";
import { searchFilterContains } from "@/utils/filters";
import capitalise from "@/filters/capitalise";
// import { determineAccessLabel } from '../../utils/apps';

const determinePillLabel = code => {
  const labelMap = {
    P: "PENDING",
    R: "DENIED",
    A: "APPROVED",
    S: "APPROVED",
    E: "ESCALATED",
    F: "FAILED"
  };
  return labelMap[code] || "UNKNOWN";
};

const determineRequestItemDescription = item => {
  if (item.application != null && item.application.applicationDesc) {
    return item.application.applicationDesc;
  }

  const roleMap = {
    teacher: "Teacher Access Package",
    sam: "Admin Manager Access Package"
  };

  return roleMap[(item.roleCode || "").toLowerCase()] || "Custom Role";
};

const determineAccessRequested = item => {
  if (item.application != null) {
    return item.tasks?.map(t => t.name).join(", ");
  }

  return determineRequestItemDescription(item);
};

const extractNames = detUserId => {
  if (!detUserId || detUserId.trim() === "") {
    return { firstName: "", lastName: "" };
  }
  const names = detUserId.split(".");
  const firstName = capitalise(names[0].replace(/[0-9]/g, ""));
  const lastName =
    names.length > 1
      ? capitalise(names[names.length - 1].replace(/[0-9]/g, ""))
      : "";
  return { firstName, lastName };
};

const requestsModule = {
  state: {
    requests: {},
    pendingRequestsCount: {},
    allPendingRequests: {},
    pendingRequestsFilter: "",
    requestHistoryFilter: ""
  },
  getters: {
    requestsByLocation: state => locationId => state.requests[locationId],
    requestsCount: state => locationId => state.requests[locationId].count,
    pendingRequestsCount: state => locationId =>
      state.pendingRequestsCount[locationId],
    allPendingRequests: state => locationId =>
      state.allPendingRequests[locationId],
    requestsByStatus: state => (locationId, rStatus) => {
      // todo: refactor this getter into pendingRequests
      // todo: refactor pending request logic into shared code

      if (!state.requests[locationId]) {
        return [];
      }

      const statusesArray = Array.isArray(rStatus)
        ? rStatus.map(s => s.toLowerCase())
        : [rStatus.toLowerCase()];
      return state.requests[locationId].filter(
        s =>
          statusesArray.indexOf((s.approvalStatus || "").toLowerCase()) !== -1
      );
    },
    pendingRequestsFiltered: (state, getters) => locationId => {
      const pendingRequests = getters.requestsByStatus(locationId, ["p", "e"]);
      // requests already sorted by request date ascending.
      return state.pendingRequestsFilter
        ? pendingRequests.filter(r =>
            searchFilterContains(
              [r.requestDescription, r.requestorFirstName, r.requestorLastName],
              state.pendingRequestsFilter
            )
          )
        : pendingRequests;
    },
    allPendingRequestsFiltered: (state, getters) => locationId => {
      const pendingRequests = getters.allPendingRequests(locationId);
      // requests already sorted by request date ascending.
      return state.pendingRequestsFilter
        ? pendingRequests.filter(r =>
            searchFilterContains(
              [r.requestDescription, r.requestorFirstName, r.requestorLastName],
              state.pendingRequestsFilter
            )
          )
        : pendingRequests;
    },
    historyFiltered: state => locationId => {
      const allRequests = state.requests[locationId];

      if (!allRequests) {
        return [];
      }

      const historyExceptPending = allRequests.filter(
        r =>
          (r.approvalStatus || "").toLowerCase() !== "p" &&
          (r.approvalStatus || "").toLowerCase() !== "e"
      );
      const result = state.requestHistoryFilter
        ? historyExceptPending.filter(r => {
            return searchFilterContains(
              [
                r.requestDescription,
                r.requestorFirstName,
                r.requestorLastName,
                r.locationName,
                r.accessRequested,
                r.pillLabel
              ],
              state.requestHistoryFilter
            );
          })
        : historyExceptPending;
      // sort by approval date/time descending
      return result.sort((r1, r2) =>
        r1.approvalTimestamp > r2.approvalTimestamp ? -1 : 1
      );
    }
  },
  mutations: {
    setRequests(state, { requests, locationId }) {
      const requestsWithDescriptions = requests.map(r => {
        const approverDisplayNames = extractNames(r.approverDetUserId);
        const requestorDisplayNames = extractNames(r.requesterDetUserId);
        Object.assign(r, {
          requestDescription: determineRequestItemDescription(r),
          accessRequested: determineAccessRequested(r),
          locationName: r.locationDetails ? r.locationDetails.name : "",
          pillLabel: determinePillLabel(r.approvalStatus), // pill label for searching
          requestorFirstName: requestorDisplayNames.firstName,
          requestorLastName: requestorDisplayNames.lastName,
          approverFirstName: approverDisplayNames.firstName,
          approverLastName: approverDisplayNames.lastName
        });
        return r;
      });

      // sort in order of date requested
      const requestsSorted = requestsWithDescriptions.sort((r1, r2) =>
        r1.requestedTimestamp > r2.requestedTimestamp ? 1 : -1
      );

      Vue.set(state.requests, locationId, requestsSorted);
    },
    setAllPendingRequests(state, { allPendingRequests, locationId }) {
      if (allPendingRequests?.length > 0) {
        const requestsWithDescriptions = allPendingRequests.map(r => {
          const approverDisplayNames = extractNames(r.approverDetUserId);
          const requestorDisplayNames = extractNames(r.requesterDetUserId);
          Object.assign(r, {
            requestDescription: determineRequestItemDescription(r),
            accessRequested: determineAccessRequested(r),
            locationName: r.locationDetails ? r.locationDetails.name : "",
            pillLabel: determinePillLabel(r.approvalStatus), // pill label for searching
            requestorFirstName: requestorDisplayNames.firstName,
            requestorLastName: requestorDisplayNames.lastName,
            approverFirstName: approverDisplayNames.firstName,
            approverLastName: approverDisplayNames.lastName
          });
          return r;
        });

        // sort in order of date requested
        const requestsSorted = requestsWithDescriptions.sort((r1, r2) =>
          r1.requestedTimestamp > r2.requestedTimestamp ? 1 : -1
        );

        return Vue.set(state.allPendingRequests, locationId, requestsSorted);
      }
      Vue.set(state.allPendingRequests, locationId, allPendingRequests);
    },
    setPendingRequestsCount(state, { count, locationId }) {
      Vue.set(state.pendingRequestsCount, locationId, count);
    },
    setPendingRequestsFilter(state, value) {
      state.pendingRequestsFilter = value;
    },
    setRequestHistoryFilter(state, value) {
      state.requestHistoryFilter = value;
    }
  },
  actions: {
    async fetchRequestHistory({ commit, state }, locationId) {
      try {
        if (locationId === null || locationId === "") {
          return;
        }

        if (state.requests[locationId]) {
          commit("setIsLoading", true);

          // data already in store
          return await new Promise(resolve => {
            resolve(state.requests[locationId]);
          }).finally(() => {
            commit("setIsLoading", false);
          });
        }
        commit("setIsLoading", true);
        return await requestsApi
          .getRequestHistory(locationId)
          .then(requests => {
            commit("setRequests", { requests, locationId });
          })
          .finally(() => {
            commit("setIsLoading", false);
          });
      } catch (error) {
        throw new Error("An error occurred: " + error.message);
      }
    },

    async fetchAllPendingRequests({ commit }, locationId) {
      try {
        if (locationId === null || locationId === "") {
          return;
        }
        commit("setIsLoading", true);
        return await requestsApi
          .getAllPendingRequestsByLocation(locationId)
          .then(allPendingRequests => {
            commit("setAllPendingRequests", { allPendingRequests, locationId });
          })
          .finally(() => {
            commit("setIsLoading", false);
          });
      } catch (error) {
        throw new Error("An error occurred: " + error.message);
      }
    },

    async fetchPendingHistoryCount({ commit }, locationId) {
      try {
        if (locationId === null || locationId === "") {
          return;
        }
        return await requestsApi
          .getPendingRequestsCountByLocation(locationId)
          .then(count => {
            commit("setPendingRequestsCount", { count, locationId });
          });
      } catch (error) {
        throw new Error("An error occurred: " + error.message);
      }
    },

    async actionRequest(
      { commit, dispatch },
      { locationId, requestId, comment, approve }
    ) {
      commit("showSnackbar", { show: false });
      commit("setIsLoading", true);

      return await requestsApi
        .actionRequest(locationId, requestId, comment, approve)
        .then(() => {
          const sbText = approve
            ? "Access request approved"
            : "Access request denied";
          commit("showSnackbar", { text: sbText, type: "success", show: true });
          // get updated list
          dispatch("fetchAllPendingRequests", locationId);
          dispatch("fetchPendingHistoryCount", locationId);
        })
        .catch(err => {
          throw new Error("An error occurred: " + err.message);
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    }
  }
};

export default requestsModule;
