<template>
  <div
    id="staff-search-results--container"
    role="dialog"
    aria-labelledby="staff-search-results--header"
    aria-describedby="staff-search-results--subheader"
    v-ads-trap-focus
    v-ads-set-focus:staff-search-result--search-field
  >
    <div class="d-flex align-start pa-6 pb-0">
      <div class="mr-3">
        <CircleIcon
          icon="mdi-account-plus-outline"
          size="48"
        />
      </div>
      <div class="mr-10">
        <h3
          id="staff-search-results--header"
        >
          Add Staff
        </h3>
        <p
          v-if="loaded"
          class="mb-0"
          id="staff-search-results--subheader"
        >
          Your search for <b>{{ filterParamString }}</b> returned
          <b>{{ staffCount }} results</b>
        </p>
        <p v-if="!loaded">
          Searching...
        </p>
      </div>
      <v-spacer />
      <v-btn
        icon
        left
        @click="$emit('close')"
        aria-label="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-btn
      class="no-shadow font-weight-medium ml-6 pl-0 mt-3"
      text
      @click="$emit('close')"
    >
      <v-icon class="mr-1">
        mdi-arrow-left
      </v-icon>
      Back to search
    </v-btn>

    <div class="mt-3">
      <div class="ml-6 mr-6">
        <v-text-field
          label=""
          class="data-table--search-field"
          outlined
          prepend-inner-icon="search"
          placeholder="Search staff"
          @input="onSearchChangeDebounced"
          aria-label="filter staff list"
          id="staff-search-result--search-field"
          hide-details
        />
      </div>

      <!-- display: block for IE11 -->
      <div style="display: block">
        <v-list
          style="background-color: inherit"
          v-if="staff.length"
        >
          <div
            v-for="s in staff"
            :key="s.detuserid"
            :id="`staff-search-result--row-${s.netRegId}`"
          >
            <v-list-item
              @click="goToStaffAppDetails(s)"
              class="pl-6 pr-6"
            >
              <InitialsIcon
                :given-name="s.firstname"
                :surname="s.lastname"
                class="mr-3"
                size="42"
              />
              <v-list-item-content class="pt-1 pb-1">
                <v-container>
                  <v-row
                    class="d-flex justify-md-space-between"
                    :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
                  >
                    <v-col class="col-md-4">
                      <v-list-item-title>
                        {{
                          s.firstname + " " + s.lastname
                        }}
                      </v-list-item-title>
                    </v-col>
                    <v-col class="col-md-3">
                      <v-list-item-subtitle>
                        {{
                          s.staffRoleFormatted
                        }}
                      </v-list-item-subtitle>
                    </v-col>
                    <v-col class="col-md-5">
                      <v-list-item-subtitle>
                        {{
                          s.schoolName | capitalise
                        }}
                      </v-list-item-subtitle>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  icon
                  ripple
                  :aria-label="'select ' + s.firstname + ' ' + s.lastname"
                >
                  <v-icon>
                    {{ isAddedExtStaffApp(s) ? 'mdi-pencil-outline' : 'mdi-plus-circle-outline' }}
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
        <div
          v-if="loaded && staff.length === 0"
          class="pt-3 pl-8"
        >
          <p>No results.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleIcon from "../components/CircleIcon";
import {InitialsIcon} from "@nswdoe/doe-ui-core";
import { mapGetters } from "vuex";
import { debounce } from "@/utils/utils";

export default {
  components: {
    CircleIcon,
    InitialsIcon,
  },
  props: {
    filterParams: {
      type: Object,
      required: true
    }
  },
  data() {
    const gn = this.filterParams.givenName || "";
    const ln = this.filterParams.lastName || "";
    const detUserId = this.filterParams.detUserId || "";

    const name = (gn + " " + ln).trim();
    const params = [];

    if (name !== "") {
      params.push(name);
    }
    if (detUserId !== "") {
      params.push(detUserId);
    }
    const filterParamString = params.join(", ");

    return {
      loaded: false,
      filterParamString,
      errors: [],
      resultCount: "",
    };
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
      staff: "staffSearchResult",
      extStaff: 'extStaffByLocation'
    }),
    resultsTitle() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.$store.getters.isLoading
          ? "Searching..."
          : this.staffCount + " Results";
      }
      return this.$store.getters.isLoading ? "" : this.staffCount + " Results";
    },
    staffCount() {
      // The api will limit results to max 100 staff. Show '100+' if 100 were returned.
      return this.resultCount > 99 ? "100+" : this.resultCount;
    },
    externalStaff() {
      return this.extStaff(this.locationId);
    }
  },
  created: async function() {
    if (this.staff && this.staff.length > 0) {
      return;
    }
    await this.$store.dispatch("searchStaff", {
      locationId: this.locationId,
      ...this.filterParams
    });
    this.resultCount = this.staff.length;
    this.loaded = true;
    if (this.staff.length > 99) {
      this.showWarningSnackbar();
    }
  },
  destroyed() {
    // force re-load search results after the dialog is closed
    this.$store.commit("setStaffSearchResult", []);
    this.$store.commit("showSnackbar", { show: false });
  },
  methods: {
    goToStaffAppDetails(staff) {
      let path = `staff-view/${staff.detuserid}`;
      if (!this.isAddedExtStaffApp(staff)) {
        // store the staff member because they won't be in the locations list and we need their detUserId in the next step
        this.$store.commit("setAddStaff", staff);
        path += `/addapplication`;
      }
      this.$router.push({ path: path });
    },
    onSearchChange(value) {
      const mutator = "setStaffSearchResultFilter";
      if (mutator) {
        this.$store.commit(mutator, value);
      }
    },
    showWarningSnackbar() {
      const snackbarOptions = {
        text:
            "Showing first 100 staff for your criteria. Refine your search criteria if you can't find who you're looking for.",
        type: "warning",
        show: true,
        allowDismiss: true,
        timeout: 10000
      };
      this.$store.commit("showSnackbar", snackbarOptions);
    },
    onSearchChangeDebounced: debounce(function(v) {
      this.onSearchChange(v);
    }, 300),
    isAddedExtStaffApp(staff) {
      return this.externalStaff.some(s => s.detuserid === staff.detuserid);
    },
  },
};
</script>

<style scoped>
div.layout {
  padding-top: 15px;
}

#msa-app.mobile .v-list .v-list-item .v-list-item__content .row .col {
  padding: 2px 0;
}
</style>
