import Vue from "vue";
import staffApi from "api/staff";
import capitalise from "@/filters/capitalise";
import { searchFilterContains } from "@/utils/filters";

const staffModule = {
  state: {
    staffByLocation: {},
    staffAnnualAttestationByLocation: {},
    staffInAttesting: [],
    attRowIdUpdate: "",
    extStaffByLocation: {},
    staffFilter: "",
    reloadStaffListRequired: false,
    staffAccessOfAppAtLocation: {},
    isApp: true
  },
  getters: {
    isApp: state => state.isApp,
    staffByLocation: state => locationId => {

      if (locationId == null) {
        return [];
      }
      return state.staffFilter && state.staffByLocation[locationId]
        ? state.staffByLocation[locationId].filter(s =>
            searchFilterContains(
              [s.firstname, s.lastname, s.staffRoleFormatted],
              state.staffFilter
            )
          )
        : state.staffByLocation[locationId];
    },
    staffAnnualAttestationByLocation: state => locationId => {
      if (locationId == null) {
        return [];
      }
      return state.staffAnnualAttestationByLocation[locationId];
    },
    isStaffInAttesting: state => detUserId => {
      return state.staffInAttesting.includes(detUserId);
    },
    attRowIdUpdate: state => state.attRowIdUpdate,
    staffAccessOfAppAtLocation: state => appId => {
      if (appId === "") {
        return [];
      }
      return state.staffAccessOfAppAtLocation[appId];
    },
    extStaffByLocation: (state, getters) => locationId => {

      if (locationId == null) {
        return [];
      }
      // the api may also return staff at this location, so filter out any duplicates
      // the apis are called asynchronously for performance, so we do the filtering of duplicates dynamically
      const staffByLocation = getters.staffByLocation(locationId) || [];
      const staffIds = staffByLocation
        .map(s => s.netRegId)
        .filter(n => n != null);
      const extStaff = state.extStaffByLocation[locationId]
        ? state.extStaffByLocation[locationId].filter(
            ext => staffIds.indexOf(ext.netRegId) === -1
          )
        : [];

      return state.staffFilter
        ? extStaff.filter(s =>
            searchFilterContains(
              [s.firstname, s.lastname, s.staffRoleFormatted],
              state.staffFilter
            )
          )
        : extStaff;
    },
    allStaffByLocation: state => locationId => {

      if (locationId == null) {
        return [];
      }
      const baseStaff = state.staffByLocation[locationId] || [];
      return baseStaff.concat(state.extStaffByLocation[locationId] || []);
    },
    staff: (state, getters) => (locationId, staffId) => {
      const allStaff = getters.allStaffByLocation(locationId);
      return allStaff.find(s => s.detuserid === staffId);
    },
    isExternalStaff: state => (locationId, staffId) => {
      const regularStaff = state.staffByLocation[locationId];
      if (regularStaff && regularStaff.length) {
        return regularStaff.map(s => s.detuserid).indexOf(staffId) === -1;
      }
      return false;
    },
    reloadStaffListRequired: state => state.reloadStaffListRequired,
    staffFilter: state => state.staffFilter,

    staffRole: (state, getters) => (locationId, staffNetRegId) => {
      const staff = getters
        .allStaffByLocation(locationId)
        .find(
          s => (s.netRegId || "").toLowerCase() === staffNetRegId.toLowerCase()
        );
      if (staff) {
        return staff.staffRoleFormatted;
      }

      return "External";
    }
  },
  mutations: {
    setIsApp(state, isApp) {
      state.isApp = isApp;
    },
    setStaffFilter(state, filter) {
      state.staffFilter = filter;
    },

    setStaff(state, { locationId, staff }) {
      // use Vue.set because we are not replacing the entire object
      Vue.set(state.staffByLocation, locationId, staff);
    },
    setStaffAnnualAttestation(state, { locationId, staffAnnualAttestation }) {
      // use Vue.set because we are not replacing the entire object
      Vue.set(
        state.staffAnnualAttestationByLocation,
        locationId,
        staffAnnualAttestation
      );
    },
    setUpdateRowId(state, detUserId) {
      state.attRowIdUpdate = detUserId;
    },
    addStaffToAttesting(state, detUserId) {
      state.staffInAttesting.push(detUserId);
    },
    removeStaffFromAttesting(state, detUserId) {
      const indexToRemove = state.staffInAttesting.indexOf(detUserId);
      if (indexToRemove !== -1) {
        state.staffInAttesting.splice(indexToRemove, 1);
      }
    },
    setStaffAccess(state, { appId, staffAccess }) {
      // use Vue.set because we are not replacing the entire object
      Vue.set(state.staffAccessOfAppAtLocation, appId, staffAccess);
    },
    resetStaffAccess(state) {
      state.staffAccessOfAppAtLocation = {};
    },
    setExtStaff(state, { locationId, staff }) {
      const extStaffMarked = staff.map(s =>
        Object.assign(s, { isExternal: true })
      );
      Vue.set(state.extStaffByLocation, locationId, extStaffMarked);
    },
    setReloadStaffListRequired(state) {
      state.reloadStaffListRequired = true;
    }
  },
  actions: {
    async fetchStaffForLocation({ commit, state }, { locationId, locType }) {
      //console.log("Inside fetchStaffForLocation from staff store : locType-" + locType + " & locationId-"+locationId);
      if (
        state.staffByLocation[locationId] &&
        state.reloadStaffListRequired === false
      ) {
        commit("setIsLoading", true);

        // data already in store
        return await new Promise(resolve => {
          resolve(state.staffByLocation[locationId]);
        }).finally(() => {
          commit("setIsLoading", false);
        });
      }
      state.reloadStaffListRequired = false;

      // load external staff asynchronously without blocking
      staffApi.getExternalStaffForLocation(locationId, locType).then(response => {
        if (response) {
          const cleanedResponse = response.map(s =>
            Object.assign(s, {
              firstname: capitalise(s.firstname),
              lastname: capitalise(s.lastname),
              staffRoleFormatted: capitalise(
                (s.staffRole || "")
                  .toLowerCase()
                  .split(".")
                  .join(" ")
              )
            })
          );
          commit("setExtStaff", {
            locationId: locationId,
            staff: cleanedResponse
          });
        } else {
          commit("setExtStaff", { locationId: locationId, staff: [] });
        }
      });

      commit("setIsLoading", true);
      return await staffApi
        .getStaffForLocation(locationId, locType)
        .then(response => {
          const staffList = response.map(s =>
            Object.assign(s, {
              firstname: capitalise(s.firstname),
              lastname: capitalise(s.lastname),
              staffRoleFormatted: capitalise(
                (s.staffRole || "")
                  .toLowerCase()
                  .split(".")
                  .join(" ")
              )
            })
          );

          //console.log("StaffList received from staffAPI: "+ JSON.stringify(staffList) + " for locationId: "+locationId)
          commit("setStaff", { locationId: locationId, staff: staffList });
          commit("setIsLoading", false);
        })
        .catch(() => {
          commit("setIsLoading", false);
        });
    },

    async fetchStaffAnnualAttestationForLocation(
      { commit, state },
      { locationId }
    ) {
      if (
        state.staffAnnualAttestationByLocation[locationId] &&
        state.reloadStaffListRequired === false
      ) {
        commit("setIsLoading", true);

        // data already in store
        return await new Promise(resolve => {
          resolve(state.staffAnnualAttestationByLocation[locationId]);
        }).finally(() => {
          commit("setIsLoading", false);
        });
      }

      commit("setIsLoading", true);
      return await staffApi
        .getStaffAnnualAttestationForLocation(locationId)
        .then(response => {
          const intl = response.staffAttestations ?? [];
          const extl = response.externalStaffAttestations ?? [];

          const intlStaff = intl.map(s =>
            Object.assign(s, {
              firstname: capitalise(s.firstname),
              lastname: capitalise(s.lastname),
              fullName: s.firstname
                ? `${capitalise(s.firstname)} ${capitalise(s.lastname)}`
                : `${capitalise(s.lastname)}`,
              isExternal: false,
              staffRoleFormatted: capitalise(
                (s.role || "")
                  .toLowerCase()
                  .split(".")
                  .join(" ")
              )
            })
          );
          const externalStaffAnnualAttestation = extl.map(s =>
            Object.assign(s, {
              firstname: capitalise(s.firstname),
              lastname: capitalise(s.lastname),
              fullName: `${capitalise(s.firstname)} ${capitalise(s.lastname)}`,
              isExternal: true,
              staffRoleFormatted: capitalise(
                (s.role || "")
                  .toLowerCase()
                  .split(".")
                  .join(" ")
              )
            })
          );
          const staffAnnualAttestation = [
            ...intlStaff,
            ...externalStaffAnnualAttestation
          ];
          commit("setStaffAnnualAttestation", {
            locationId,
            staffAnnualAttestation
          });
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    },

    async fetchStaffAccessOfAppAtLocation(
      { commit, state },
      { locationId, locType, appId }
    ) {
      try {
        commit("setIsLoading", true);
        const staffAccessOfAppAtLocation =
          state.staffAccessOfAppAtLocation || {};
        const reloadStaffListRequired = state.reloadStaffListRequired || false;

        if (!reloadStaffListRequired && staffAccessOfAppAtLocation[appId]) {
          commit("setIsLoading", false);
          return staffAccessOfAppAtLocation[appId];
        }
        state.reloadStaffListRequired = false;

        //console.log("Before calling getStaffAccessOfAppAtLocation from staff store: locType-"+locType);
        const response = await staffApi.getStaffAccessOfAppAtLocation(
          locationId,
          locType,
          appId
        );
        const selectedAppId = this.getters.selectedAppId;
        const selectedApp = this.getters.schoolApps.find(
          sA => sA.applicationId === selectedAppId
        );
        const selectedAppTasks = selectedApp.tasks.map(
          ({ taskId, taskName }) => ({ taskId, taskName })
        );

        const {
          staffApplications = [],
          additionalStaffApplications = []
        } = response;
        const UNKNOWN = "Unknown";
        const processStaffAccess = (apps, isAdditional) => {
          return apps?.map(s => {
            let taskInfo = {};
            const _staff = isAdditional ? s.additionalStaff : s.staff;
            selectedAppTasks.map(t => {
              const taskName = t.taskName;
              const index = s.application.tasks?.findIndex(
                t => t.taskName === taskName
              );
              if (index !== undefined && index !== -1) {
                taskInfo[taskName] = s.application.tasks[index].hasAccess;
                taskInfo[`${taskName}Birthright`] =
                  s.application.tasks[index].birthright;
                taskInfo[`${taskName}TaskId`] =
                  s.application.tasks[index].taskId;
                taskInfo[`${taskName}TaskName`] =
                  s.application.tasks[index].taskName;
              } else {
                taskInfo[taskName] = false;
                taskInfo[`${taskName}Birthright`] = false;
                taskInfo[`${taskName}TaskId`] = t.taskId;
                taskInfo[`${taskName}TaskName`] = taskName;
              }
            });
            return {
              firstName: _staff.firstname ?? UNKNOWN,
              lastName: _staff.lastname ?? UNKNOWN,
              detUserId: _staff.detuserid ?? UNKNOWN,
              netRegId: _staff.netRegId ?? UNKNOWN,
              role: _staff.staffRole ?? UNKNOWN,
              staffRoleFormatted: capitalise(
                (_staff.staffRole ?? UNKNOWN)
                  .toLowerCase()
                  .split(".")
                  ?.pop()
              ),
              email: _staff.email?.toLowerCase() ?? UNKNOWN.toLowerCase(),
              appId: s.application.applicationId,
              isAdditional,
              ...taskInfo
            };
          });
        };
        const internalStaffAccess = processStaffAccess(
          staffApplications,
          false
        );
        const additionalStaffAccess = processStaffAccess(
          additionalStaffApplications,
          true
        );
        commit("setStaffAccess", {
          appId,
          staffAccess: [...internalStaffAccess, ...additionalStaffAccess]
        });
      } catch (e) {
        throw new Error("An error occurred: " + e.message);
      } finally {
        commit("setIsLoading", false);
      }
    },
    resetPassword({ commit }, { locationId, staffId }) {
      commit("setIsLoading", true);
      return staffApi
        .resetPassword(locationId, staffId)
        .finally(() => commit("setIsLoading", false));
    }
  }
};

export default staffModule;
