const determineAccessLabel = (tasks) => {

  if (tasks.every(t => t.hasAccess)) {
    return "FULL";
  } 
  
  if (tasks.some(t => t.hasAccess)) {
    return "PARTIAL"
  }
  
  return "NONE";
};

export { determineAccessLabel };