<template>
  <v-snackbar
    :value="value"
    :class="snackbarClass"
    bottom
    left
    :timeout="sbTimeout"
    id="app--snackbar"
  >
    <slot name="content">
      <div
        class="d-flex align-center"
        :class="{'ie-snackbar-content': isIe}"
      >
        <slot name="icon">
          <v-icon
            :color="typeProperties.color"
            size="35"
            class="mr-2"
          >
            {{ sbIcon }}
          </v-icon>
        </slot>
        <slot name="text">
          <div
            v-if="htmlText"
            class="snackbar--text"
            v-html="htmlText"
          />
          <div
            v-if="!htmlText"
            class="snackbar--text"
          >
            {{ text }}
          </div>
        </slot>
        <div>
          <v-btn
            v-if="allowDismiss"
            class="snackbar--text"
            text
            @click="dismiss"
          >
            Close
          </v-btn>
        </div>
      </div>
    </slot>
  </v-snackbar>
</template>

<script>
const types = {
  success: {
    class: 'snackbar--success',
    icon: 'mdi-check-circle',
    color: '#00A908'
  },
  warning: {
    class: 'snackbar--warning',
    icon: 'mdi-alert',
    color: '#DC5800'
  },
  error: {
    class: 'snackbar--error',
    icon: 'mdi-alert-circle',
    color: '#B81237'
  },
  info: {
    class: 'snackbar--info',
    icon: 'mdi-information',
    color: '#6D7079'
  }
}

export default {
    props: {
      type: {
        type: String,
        default: 'info'
      },
      icon: {
        type: String,
        default: null
      },
      text: {
        type: String,
        default: null
      },
      allowDismiss: {
        type: Boolean
      },
      value: {
        type: Boolean
      },
      timeout: {
        type: Number,
        default: 5000
      },
      htmlText: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        isIe: document.documentMode != null
      }
    },
    computed: {
      typeProperties() {
        return types[this.type.toLowerCase()];
      },
      snackbarClass() {
        return 'ml-2 mr-2 ' + this.typeProperties.class;
      },
      sbIcon() {
        return this.icon || this.typeProperties.icon;
      },
      sbTimeout() {
        const timeout = parseInt(this.timeout);
        return isNaN(timeout) ? 2500 : timeout;
      }
    },
    methods: {
      dismiss: function() {
        this.$store.commit('showSnackbar', {show: false, type: this.$store.getters.snackbar.type});
      }
    },
    watch: {
      '$route': function() {
        if (this.$store.getters.snackbar.show) {
          this.dismiss();
        }
      }
    }
}
</script>

<style lang='scss' scoped>
  div.v-snack {
    width: auto;
    margin: auto;

    // // IE11 overflow wrapping fix
    .ie-snackbar-content {
      width: 500px;
      .snackbar--text {
        flex: 1 1 0%;
      }
    }

    .snackbar--text {
      color: #333333;
    }

    ::v-deep .v-snack__content {
      padding: 25px;
    }

    ::v-deep .v-snack__wrapper {
      background-color: #FFFFFF;
    }

    &.v-snack--bottom{
      left: 20px;
      bottom: 20px;
    }
  }
      
</style>
