<template>  
  <router-view />
</template>

<script>
export default {
  name: "Provision"
}
</script>

<style scoped>

</style>